import React, { Component } from 'react';
import { Container, Row, Col, Button, Modal, Stack } from 'react-bootstrap';

export class ThemeColorModal extends Component {
  render() {
    return (
      <Modal size="lg" show={this.props.showModal} onHide={this.props.handleCloseModal}>
        <Modal.Body className="show-grid">
          <Container>
            <Row lg={4} md={2} sm={2} xs={2}>
              {this.props.themeColors.map((themeColor, index) => (
                <Col key={index} className="mt-2 mb-2 d-grid">
                  <Button key={index} variant="light" onClick={() => this.props.handleSelect(themeColor)}>
                    <Row>
                      <Col xs="6">
                        {themeColor.name}
                      </Col>
                      <Col xs="6" className="my-auto">
                        <Stack direction="horizontal">
                          {themeColor.heatmapColors.map((heatmapColor, heatmapColorIndex) => (
                            <div key={heatmapColorIndex} className="theme-color" style={{ backgroundColor: heatmapColor }}></div>
                          ))}
                        </Stack>
                      </Col>
                    </Row>
                  </Button>
                </Col>
              ))}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleCloseModal}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
