import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import handleClickEmail from '../../functions/handleClickEmail';

export default function EmailModal({
  showModal,
  authType,
  handleCloseModal = f => f,
  setProcessing = f => f
}) {
  const [email, setEmail] = useState("");

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  }

  const handleSendMail = async () => {
    await handleClickEmail(email, authType, setProcessing, handleCloseModal);
  }

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleClickEmail(email, authType, setProcessing, handleCloseModal);
    }
  }

  return (
    <Modal show={showModal} onHide={handleCloseModal} >
      <Modal.Header>
        <Modal.Title>メール認証</Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Form.Control type="email" placeholder="メールアドレスを入力してください" value={email} onChange={(e) => handleChangeEmail(e)} onKeyDown={(e) => handleKeyDown(e)} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSendMail}>メール送信</Button>
      </Modal.Footer>
    </Modal>
  )
}
