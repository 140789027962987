import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Accordion, Stack, Alert, Image, Spinner, Card } from 'react-bootstrap';
import Encoding from 'encoding-japanese';
import Header from './Header';
import { ThemeColorModal } from './modals/ThemeColorModal';
import EmailModal from './modals/EmailModal';
import handleClickGoogle from '../functions/handleClickGoogle';
import handleClickLINE from '../functions/handleClickLINE';
import getServerUrl from '../functions/getServerUrl';
import getDateString from '../functions/getDateString';
import { CSV } from '../functions/csv';

const _version = "2.0.5";

export default function Setting() {
  const userId = localStorage.getItem("userId");
  const sessionId = localStorage.getItem("sessionId")
  const auth = {
    userId: userId,
    sessionId: sessionId
  };
  const guest = localStorage.getItem("guest");

  const [showEmailModal, setShowEmailModal] = useState(false);

  const [themeColors, setThemeColors] = useState([]);
  const [showThemeColorModal, setShowThemeColorModal] = useState(false);

  const initialUserInfo = {
    userName: "",
    howToAnswerQuiz: null,
    isQuizTakenBeforeRecord: false,
    themeColor: null,
    isOneYearPlanDisplayed: false,
    isEmailLinked: false,
    email: "",
    isGoogleLinked: false,
    isLINELinked: false,
    canRemoveAuth: false
  };
  const [userInfo, setUserInfo] = useState(initialUserInfo);

  const authType = "add";
  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    getUserInfo();
    getThemeColors();
  }, []);

  const getUserInfo = async () => {
    try {
      setProcessing(true);
      const res = await fetch(getServerUrl() + "Users/me?userId=" + userId + "&sessionId=" + sessionId);
      const data = await res.json();
      const user = data.user;
      const authType = data.authType;

      const newUserInfo = {
        userName: user.name,
        howToAnswerQuiz: user.howToAnswerQuiz,
        isQuizTakenBeforeRecord: user.isQuizTakenBeforeRecord,
        themeColor: user.themeColor,
        isOneYearPlanDisplayed: user.isOneYearPlanDisplayed,
        isEmailLinked: authType.isEmailLinked,
        email: authType.email,
        isGoogleLinked: authType.isGoogleLinked,
        isLINELinked: authType.isLINELinked,
        canRemoveAuth: authType.canRemoveAuth
      };
      setUserInfo(newUserInfo);
      setProcessing(false);
    }
    catch (e) {
      window.location.href = "/login";
    }
  }

  const getThemeColors = async () => {
    const res = await fetch(getServerUrl() + "ThemeColors/all?userId=" + userId + "&sessionId=" + sessionId);
    const data = await res.json();
    const newThemeColors = data.themeColors;
    setThemeColors(newThemeColors)
  }

  const handleLogonFromGuest = () => {
    window.location.href = "/logon_from_guest";
  }

  const handleLogin = () => {
    window.location.href = "/login";
  }

  const handleRemoveEmail = async () => {
    var result = window.confirm("メールアドレスを削除しますか？");

    if (result) {
      setProcessing(true);

      try {
        const data = {
          auth: auth
        };
        console.log(data);

        const res = await fetch(getServerUrl() + "Auth/email/remove", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });

        const resdata = await res.json();
        console.log(resdata);
        const success = resdata.success;

        setProcessing(false);

        if (success) {
          window.location.reload();
        }
        else {
          throw new Error();
        }
      }
      catch (e) {
        window.alert("失敗しました。");
        console.error(e);
      }
    }
  }

  const handleRemoveGoogle = async () => {
    var result = window.confirm("Google連携を削除しますか？");

    if (result) {
      setProcessing(true);

      try {
        const data = {
          auth: auth
        };
        console.log(data);

        const res = await fetch(getServerUrl() + "Auth/google/remove", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });

        const resdata = await res.json();
        console.log(resdata);
        const success = resdata.success;

        setProcessing(false);

        if (success) {
          window.location.reload();
        }
        else {
          throw new Error();
        }
      }
      catch (e) {
        window.alert("失敗しました。");
        console.error(e);
      }
    }
  }

  const handleRemoveLINE = async () => {
    var result = window.confirm("LINE連携を削除しますか？");

    if (result) {
      setProcessing(true);

      try {
        const data = {
          auth: auth
        };
        console.log(data);

        const res = await fetch(getServerUrl() + "Auth/line/remove", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });

        const resdata = await res.json();
        console.log(resdata);
        const success = resdata.success;

        setProcessing(false);

        if (success) {
          window.location.reload();
        }
        else {
          throw new Error();
        }
      }
      catch (e) {
        window.alert("失敗しました。");
        console.error(e);
      }
    }
  }

  const handleChangeUserName = (event) => {
    const newUserInfo = {
      userName: event.target.value
    };
    setUserInfo({ ...userInfo, ...newUserInfo });
  }

  const handleClickIsQuizBeAnsweredByChoices = () => {
    const newUserInfo = {
      howToAnswerQuiz: "Choices"
    };
    setUserInfo({ ...userInfo, ...newUserInfo });
  }

  const handleClickIsQuizBeAnsweredByScript = () => {
    const newUserInfo = {
      howToAnswerQuiz: "Script"
    };
    setUserInfo({ ...userInfo, ...newUserInfo });
  }

  const handleChangeIsQuizTakenBeforeRecord = () => {
    const newUserInfo = {
      isQuizTakenBeforeRecord: !userInfo.isQuizTakenBeforeRecord
    };
    setUserInfo({ ...userInfo, ...newUserInfo });
  }

  const handleChangeIsOneYearPlanDisplayed = () => {
    const newUserInfo = {
      isOneYearPlanDisplayed: !userInfo.isOneYearPlanDisplayed
    };
    setUserInfo({ ...userInfo, ...newUserInfo });
  }

  const handleSave = async () => {
    const data = {
      auth: auth,
      name: userInfo.userName,
      howToAnswerQuiz: userInfo.howToAnswerQuiz,
      isQuizTakenBeforeRecord: userInfo.isQuizTakenBeforeRecord,
      themeColor: userInfo.themeColor.id,
      isOneYearPlanDisplayed: userInfo.isOneYearPlanDisplayed
    };
    console.log(data);

    try {
      setProcessing(true);
      await fetch(getServerUrl() + "Users/change_info", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      setProcessing(false);
      window.location.reload();
    }
    catch (e) {
      window.alert("失敗しました。");
      console.error(e);
    }
  }

  const handleDeleteAccount = async () => {
    var result = window.confirm("アカウントを削除するとこれまでの記録が全て削除されます。本当によろしいでしょうか？");

    if (result) {

      try {
        await fetch(getServerUrl() + "Users/delete?userId=" + userId + "&sessionId=" + sessionId, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
        });

        localStorage.removeItem("userId");
        localStorage.removeItem("sessionId");
        localStorage.removeItem("guest");

        window.location.href = "/";
      }
      catch (e) {
        window.alert("失敗しました。");
        console.error(e);
      }
    }
  }

  const handleShowEmailModal = () => {
    setShowEmailModal(true);
  }
  const handleCloseEmailModal = () => {
    setShowEmailModal(false);
  }

  const handleShowThemeColorModal = () => {
    setShowThemeColorModal(true);
  }
  const handleCloseThemeColorModal = () => {
    setShowThemeColorModal(false);
  }
  const handleSelectThemeColor = (themeColor) => {
    const newUserInfo = {
      themeColor: themeColor
    };
    setUserInfo({ ...userInfo, ...newUserInfo });

    handleCloseThemeColorModal();
  }

  const handleExportRecord = async () => {
    const getCount = async (userId, sessionId) => {
      const res = await fetch(getServerUrl() + "ReadingRecords/range/all?userId=" + userId + "&sessionId=" + sessionId + "&offset=0&limit=1");
      const data = await res.json();
      const count = data.totalCount;
      return count;
    }

    const getReadingRecords = async (userId, sessionId, offset, limit) => {
      const res = await fetch(getServerUrl() + "ReadingRecords/range/all?userId=" + userId + "&sessionId=" + sessionId + "&offset=" + offset + "&limit=" + limit);
      const data = await res.json();
      const readingRecords = data.readingRecords;
      return readingRecords;
    }

    let exportData = "";
    exportData += "\"日付\",\"本\",\"章\",\"開始節\",\"終了節\",\"エホバについてどんなことが分かるか\",\"聖書の中心テーマとどう関係しているか\",\"自分の生活でどのように役立てられるか\",\"人を支え励ますためにどのように使えるか\"\n";

    // 過去から100件ずつダウンロードする
    const count = await getCount(userId, sessionId);
    const number = 100;
    let page = Math.floor((count - 1) / number);
    while (page >= 0) {
      const offset = page * number;
      const limit = number;
      const readingRecords = await getReadingRecords(userId, sessionId, offset, limit);

      for (let i = readingRecords.length - 1; i >= 0; i--) {
        const readingRecord = readingRecords[i];
        const date = getDateString(new Date(readingRecord.date), '/');
        const bookId = readingRecord.readingRange.bookId;
        const chapter = readingRecord.readingRange.chapter;
        const startVerse = readingRecord.readingRange.startVerse;
        const endVerse = readingRecord.readingRange.endVerse;
        const aboutJehovah = readingRecord.comment.aboutJehovah.replace("\"", "\"\"");
        const aboutContribute = readingRecord.comment.aboutContribute.replace("\"", "\"\"");
        const aboutApply = readingRecord.comment.aboutApply.replace("\"", "\"\"");
        const aboutHelp = readingRecord.comment.aboutHelp.replace("\"", "\"\"");

        const data = "\"" + date + "\",\"" + bookId + "\",\"" + chapter + "\",\"" + startVerse + "\",\"" + endVerse + "\",\"" + aboutJehovah + "\",\"" + aboutContribute + "\",\"" + aboutApply + "\",\"" + aboutHelp + "\"\n";
        exportData += data;
      }

      page -= 1;
    }

    const str = Encoding.stringToCode(exportData);
    const convert = Encoding.convert(str, 'sjis', 'unicode');
    const u8a = new Uint8Array(convert);

    const blob = new Blob([u8a], { "type": "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    a.href = url;
    a.download = "tsudokukun-通読記録.csv"
    a.click();
    window.URL.revokeObjectURL(url);
    a.parentNode.removeChild(a);

    window.alert("通読記録のエクスポート完了しました。");
  }

  const handleExportMemory = async () => {
    const getCount = async (userId, sessionId) => {
      const res = await fetch(getServerUrl() + "ScripturesToMemorize/range/all?userId=" + userId + "&sessionId=" + sessionId + "&offset=0&limit=1");
      const data = await res.json();
      const count = data.totalCount;
      return count;
    }

    const getScripturesToMemorize = async (userId, sessionId, offset, limit) => {
      const res = await fetch(getServerUrl() + "ScripturesToMemorize/range/all?userId=" + userId + "&sessionId=" + sessionId + "&offset=" + offset + "&limit=" + limit);
      const data = await res.json();
      const scripturesToMemorize = data.scripturesToMemorize;
      return scripturesToMemorize;
    }

    let exportData = "";
    exportData += "\"本\",\"章\",\"節\",\"本文\"\n";

    // 過去から100件ずつダウンロードする
    const count = await getCount(userId, sessionId);
    const number = 100;
    let page = Math.floor((count - 1) / number);
    while (page >= 0) {
      const offset = page * number;
      const limit = number;
      const scripturesToMemorize = await getScripturesToMemorize(userId, sessionId, offset, limit);

      for (let i = scripturesToMemorize.length - 1; i >= 0; i--) {
        const scriptureToMemorize = scripturesToMemorize[i];
        const scripture = scriptureToMemorize.scripture;
        const text = scripture.text;
        const script = scripture.script;
        const bookId = script.bookId;
        const chapter = script.chapter;
        const verse = script.verse;

        const data = "\"" + bookId + "\",\"" + chapter + "\",\"" + verse + "\",\"" + text + "\"\n";
        exportData += data;
      }

      page -= 1;
    }

    const str = Encoding.stringToCode(exportData);
    const convert = Encoding.convert(str, 'sjis', 'unicode');
    const u8a = new Uint8Array(convert);

    const blob = new Blob([u8a], { "type": "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    a.href = url;
    a.download = "tsudokukun-覚える聖句.csv"
    a.click();
    window.URL.revokeObjectURL(url);
    a.parentNode.removeChild(a);

    window.alert("覚える聖句のエクスポート完了しました。");
  }

  const handleImportRecord = async () => {
    const createReadingRecord = async (auth, readingRecord) => {
      const data = {
        auth: auth,
        readingRecord: readingRecord
      };

      await fetch(getServerUrl() + "ReadingRecords/create", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
    }

    const input = document.createElement("input");
    document.body.appendChild(input);
    input.style = "display:none";
    input.type = "file";
    input.accept = ".csv";
    input.click();
    input.addEventListener("change", () => {
      let reader = new FileReader();
      reader.readAsText(input.files[0], "Shift_JIS");
      reader.onload = async () => {
        let array = CSV.parse(reader.result);
        console.log(array);

        // 1行目は読み飛ばし
        array.shift();

        for (let i = 0; i < array.length; i++) {
          console.log(array[i]);
          const elements = array[i];
          if (elements.length < 7) continue;

          const createTime = new Date(Date.now()).toISOString();
          let readingRecord = {
            createTime: createTime,
            date: getDateString(new Date(elements[0])),
            readingRange: {
              bookId: parseInt(elements[1], 10),
              chapter: parseInt(elements[2], 10),
              startVerse: parseInt(elements[3], 10),
              endVerse: parseInt(elements[4], 10),
            },
            comment: {
              aboutJehovah: elements[5],
              aboutContribute: elements[6],
              aboutApply: elements[7],
              aboutHelp: elements[8]
            }
          }

          await createReadingRecord(auth, readingRecord);
        }

        window.alert("通読記録のインポート完了しました。");
      };
    });
  }

  const handleImportMemory = async () => {
    const createMemory = async (auth, memory) => {
      const data = {
        auth: auth,
        createTime: memory.createTime,
        bookId: memory.bookId,
        chapter: memory.chapter,
        verse: memory.verse,
        text: memory.text
      };

      await fetch(getServerUrl() + "ScripturesToMemorize/create", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
    }

    const input = document.createElement("input");
    document.body.appendChild(input);
    input.style = "display:none";
    input.type = "file";
    input.accept = ".csv";
    input.click();
    input.addEventListener("change", () => {
      let reader = new FileReader();
      reader.readAsText(input.files[0], "Shift_JIS");
      reader.onload = async () => {
        let array = CSV.parse(reader.result);
        console.log(array);

        // 1行目は読み飛ばし
        array.shift();

        for (let i = 0; i < array.length; i++) {
          console.log(array[i]);
          const elements = array[i];
          if (elements.length < 4) continue;

          const createTime = new Date(Date.now()).toISOString();
          const memory = {
            createTime: createTime,
            bookId: parseInt(elements[0], 10),
            chapter: parseInt(elements[1], 10),
            verse: parseInt(elements[2], 10),
            text: elements[3]
          }

          // 1件ずつアップロード
          await createMemory(auth, memory);
        }

        window.alert("覚える聖句のインポート完了しました。");
      };
    });
  }

  return (
    <>
      <EmailModal
        showModal={showEmailModal}
        authType={authType}
        handleCloseModal={handleCloseEmailModal}
        setProcessing={setProcessing}
      />

      <ThemeColorModal
        showModal={showThemeColorModal}
        themeColors={themeColors}
        handleCloseModal={handleCloseThemeColorModal}
        handleSelect={handleSelectThemeColor}
      />

      {processing === true ?
        <div className="color-overlay d-flex justify-content-center align-items-center">
          <Form className="form rounded p-4 p-sm-3">
            <div className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          </Form>
        </div>
        :
        <>
          <Header />

          <Container className="mt-3">
            {guest !== null &&
              <Row className="mb-3">
                <Col>
                  <Alert variant="success">
                    <div className="mb-1">
                      あなたはゲストユーザーです。
                      1か月間データの更新がないと自動的にアカウントが削除されます。
                      正規ユーザーとしてアカウント登録するとアカウントが削除されることはありません。
                      正規ユーザーとしてアカウント登録することをご検討ください。
                    </div>
                    <Stack direction="horizontal">
                      <Button variant="outline-success" onClick={handleLogonFromGuest}>
                        正規ユーザーとしてアカウント登録
                      </Button>
                      <Button variant="outline-success" className="gap-left" onClick={handleLogin}>
                        別アカウントでログイン
                      </Button>
                    </Stack>
                  </Alert>
                </Col>
              </Row>
            }

            {guest === null &&
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>アカウント設定</Card.Title>
                  <Container>
                    <Row className="mb-4">
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          <Image className="me-1" src="mail_logo.png" height={30}></Image>
                          メールアドレス
                        </Form.Label>
                        {userInfo.isEmailLinked &&
                          <>
                            <Form.Label column sm="4">
                              連携済み： {userInfo.email}
                            </Form.Label>
                            {userInfo.canRemoveAuth &&
                              <Col sm="4">
                                <Button variant="outline-danger" onClick={handleRemoveEmail}>
                                  連携を解除する
                                </Button>
                              </Col>
                            }
                          </>
                        }
                        {!userInfo.isEmailLinked &&
                          <Col sm="4">
                            <Button variant="success" onClick={handleShowEmailModal}>
                              連携する
                            </Button>
                          </Col>
                        }
                      </Form.Group>
                    </Row>

                    <Row className="mb-4">
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          <Image className="me-1" src="google_logo.png" height={30}></Image>
                          Google
                        </Form.Label>
                        {userInfo.isGoogleLinked &&
                          <>
                            <Form.Label column sm="4">
                              連携済み
                            </Form.Label>

                            {userInfo.canRemoveAuth &&
                              <Col sm="4">
                                <Button variant="outline-danger" onClick={handleRemoveGoogle}>
                                  連携を解除する
                                </Button>
                              </Col>
                            }
                          </>
                        }
                        {!userInfo.isGoogleLinked &&
                          <Col sm="4">
                            <Image className="image-button mx-auto" src="btn_google_signin_light_normal_web.png" onClick={() => handleClickGoogle(authType, setProcessing)}></Image>
                          </Col>
                        }
                      </Form.Group>
                    </Row>

                    <Row className="mb-4">
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          <Image className="me-1" src="LINE_Brand_icon.png" height={30}></Image>
                          LINE
                        </Form.Label>
                        {userInfo.isLINELinked &&
                          <>
                            <Form.Label column sm="4">
                              連携済み
                            </Form.Label>

                            {userInfo.canRemoveAuth &&
                              <Col sm="4">
                                <Button variant="outline-danger" onClick={handleRemoveLINE}>
                                  連携を解除する
                                </Button>
                              </Col>
                            }
                          </>
                        }
                        {!userInfo.isLINELinked &&
                          <Col sm="4">
                            <Image className="image-button mx-auto" src="btn_login_base.png" onClick={() => handleClickLINE(authType, setProcessing)}></Image>
                          </Col>
                        }
                      </Form.Group>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            }

            <Card className="mb-4">
              <Card.Body>
                <Card.Title>一般設定</Card.Title>
                <Container>
                  <Form>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column xs="4" sm="3" md="2">
                        名前
                      </Form.Label>
                      <Col xs="8" sm="9" md="10">
                        <Form.Control type="text" value={userInfo.userName} onChange={(e) => handleChangeUserName(e)} />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column xs="4" sm="3" md="2" className="my-auto">
                        テーマカラー
                      </Form.Label>
                      <Col xs="8" sm="9" md="10">
                        <Button variant="light" onClick={handleShowThemeColorModal}>
                          <Row>
                            <Col xs="6">
                              {userInfo.themeColor?.name}
                            </Col>
                            <Col xs="6" className="my-auto">
                              <Stack direction="horizontal">
                                {userInfo.themeColor?.heatmapColors.map((heatmapColor, heatmapColorIndex) => (
                                  <div key={heatmapColorIndex} className="theme-color" style={{ backgroundColor: heatmapColor }}></div>
                                ))}
                              </Stack>
                            </Col>
                          </Row>
                        </Button>
                      </Col>
                    </Form.Group>

                    <Form.Check
                      className="mb-3"
                      type="checkbox"
                      label="1年で読み通す計画を表示する"
                      id="isOneYearPlanDisplayed"
                      checked={userInfo.isOneYearPlanDisplayed}
                      onChange={handleChangeIsOneYearPlanDisplayed}
                    />

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column xs="4" sm="3" md="2">
                        クイズの解答方法
                      </Form.Label>
                      <Form.Label column xs="8" sm="9" md="10">
                        <Form.Check
                          inline
                          label="選択肢から選ぶ"
                          name="howToAnswerQuiz"
                          type="radio"
                          id="isQuizBeAnsweredByChoices"
                          checked={userInfo.howToAnswerQuiz === "Choices"}
                          onChange={handleClickIsQuizBeAnsweredByChoices}
                        />
                        <Form.Check
                          inline
                          label="聖句を指定する"
                          name="howToAnswerQuiz"
                          type="radio"
                          id="isQuizBeAnsweredByScript"
                          checked={userInfo.howToAnswerQuiz === "Script"}
                          onChange={handleClickIsQuizBeAnsweredByScript}
                        />
                      </Form.Label>
                    </Form.Group>

                    <Form.Check
                      className="mb-3"
                      type="checkbox"
                      label="記録前にクイズする（「覚える聖句」を登録している場合）"
                      id="isQuizTakenBeforeRecord"
                      checked={userInfo.isQuizTakenBeforeRecord}
                      onChange={handleChangeIsQuizTakenBeforeRecord}
                    />

                    <Button className="mt-3" variant="success" type="button" onClick={handleSave}>
                      保存
                    </Button>
                  </Form>
                </Container>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Body>
                <Card.Title>エクスポートとインポート</Card.Title>
                <Card.Subtitle><a href="https://tsudokukun.jp/csv">フォーマットについて</a></Card.Subtitle>
                <Container>
                  <Row>
                    <Col lg={3} md={4} sm={6} xs={12}>
                      <div>通読記録</div>
                      <Stack direction="horizontal">
                        <Button variant="success" onClick={handleExportRecord}>
                          エクスポート
                        </Button>
                        <Button variant="success" className="gap-left" onClick={handleImportRecord}>
                          インポート
                        </Button>
                      </Stack>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12}>
                      <div>覚える聖句</div>
                      <Stack direction="horizontal">
                        <Button variant="success" onClick={handleExportMemory}>
                          エクスポート
                        </Button>
                        <Button variant="success" className="gap-left" onClick={handleImportMemory}>
                          インポート
                        </Button>
                      </Stack>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>

            <Row className='mt-5'>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>その他</Accordion.Header>
                  <Accordion.Body>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        アカウント削除
                      </Form.Label>
                      <Col sm="8">
                        <Button variant="danger" type="button" onClick={handleDeleteAccount}>
                          アカウント削除
                        </Button>
                      </Col>
                    </Form.Group>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Row>

            <Row className="mt-5">
              <Col>
                Version {_version}
              </Col>
            </Row>
          </Container >
        </>
      }
    </>
  )
}
