import getServerUrl from './getServerUrl';
import getGoogleRedirectUri from './getGoogleRedirectUri';

// authType: "logon", "login", "logonFromGuest" or "add"
export default async function handleClickGoogle(authType, setProcessing = f => f) {
  setProcessing(true);
  try {
    const res = await fetch(getServerUrl() + "Auth/google/get_state", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const data = await res.json();
    console.log(data);

    const state = data.state;
    const redirectUri = getGoogleRedirectUri();
    const url = "https://accounts.google.com/o/oauth2/v2/auth?client_id=647598398462-nk6fo2ek3bg5vr72k90agtur55jdf1ce.apps.googleusercontent.com&response_type=code&scope=openid%20profile&redirect_uri=" + redirectUri + "&state=" + state

    localStorage.setItem("authType", authType);

    window.location.href = url;
  }
  catch (e) {
    console.error(e);
    setProcessing(false);
  }
}
