export default function getPlanString(startBookId, startChapter, startVerse, endBookId, endChapter, endVerse, books) {
  const startBook = books[startBookId];
  const endBook = books[endBookId];

  var result = startBook.shortName + startChapter;
  if (startVerse !== null) {
    result += ":" + startVerse;
  }

  if (startBookId !== endBookId) {
    result += "-";
    result += endBook.shortName + endChapter;
    if (endVerse !== null) {
      result += ":" + endVerse;
    }
  }
  else {
    if (startChapter !== endChapter) {
      result += "-";
      result += endChapter;
      if (endVerse !== null) {
        result += ":" + endVerse;
      }
    }
    else if (endVerse !== null && startVerse !== endVerse) {
      result += "-";
      result += endVerse;
    }
  }

  return result;
}