import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Stack, ListGroup, OverlayTrigger, Tooltip, Accordion, ToggleButton, Card, Alert } from 'react-bootstrap';
import { ArrowLeftCircle, ArrowRightCircle, PencilSquare, EyeSlashFill } from 'react-bootstrap-icons';
import Header from './Header';
import ReadingRangeSelectionModal from './modals/ReadingRangeSelectionModal';
import QuizModal from './modals/QuizModal';
import { RecordModal } from './modals/RecordModal';
import getServerUrl from '../functions/getServerUrl';
import getDateString from '../functions/getDateString';
import getCommentString from '../functions/getCommentString';
import getWeekHeatmaps from '../functions/getWeekHeatmaps';
import getReadingRangeString from '../functions/getReadingRangeString';
import getReadingRangeUrl from '../functions/getReadingRangeUrl';
import getNextReadingRange from '../functions/getNextReadingRange';
import getPlanString from '../functions/getPlanString';
import getDailyFromReadingRecords from '../functions/getDailyFromReadingRecords';
import './style.css';

const _version = "2.0.5";

export default function Home() {
  const userId = localStorage.getItem("userId");
  const sessionId = localStorage.getItem("sessionId")
  const auth = {
    userId: userId,
    sessionId: sessionId
  };
  const guest = localStorage.getItem("guest")

  const [sections, setSections] = useState({});
  const [books, setBooks] = useState({});

  const [showRecommend, setShowRecommend] = useState(false);
  const [countOfRecentRecords, setCountOfRecentRecords] = useState(-1);

  const initialUserInfo = {
    howToAnswerQuiz: null,
    isQuizTakenBeforeRecord: false,
    themeColor: null,
    isOneYearPlanDisplayed: false
  };
  const [userInfo, setUserInfo] = useState(initialUserInfo);

  const initialReadingRange = {
    book: null,
    chapter: "-",
    startVerse: "-",
    endVerse: "-"
  };
  const [readingRange, setReadingRange] = useState(initialReadingRange);
  const [readingRangeString, setReadingRangeString] = useState("");

  const [recentReadingRecords, setRecentReadingRecords] = useState([]);

  const [plan, setPlan] = useState("");
  const [date, setDate] = useState("");

  const initialComment = {
    aboutJehovah: "",
    aboutContribute: "",
    aboutApply: "",
    aboutHelp: ""
  }
  const [comment, setComment] = useState(initialComment);

  const [isCreating, setIsCreating] = useState(true); // 新規作成中はtrue。編集中はfalse
  const [editingReadingRecordCreateTime, setEditingReadingRecordCreateTime] = useState(-1);

  const [showReadingRangeSelectionModal, setShowReadingRangeSelectionModal] = useState(false);
  const [showQuizModal, setShowQuizModal] = useState(false);
  const [showRecordModal, setShowRecordModal] = useState(false);

  const [selectedReadingRecord, setSelectedReadingRecord] = useState(null);

  const initialYearAndMonthOfCalendar = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1
  };
  const [yearAndMonthOfCalendar, setYearAndMonthOfCalendar] = useState(initialYearAndMonthOfCalendar);

  const [weekHeatmaps, setWeekHeatmaps] = useState([]);

  const [coveredVersesReadingRecords, setCoveredVersesReadingRecords] = useState([]);
  const [recentDailyReadingRecords, setRecentDailyReadingRecords] = useState(null);

  const [isRecord, setIsRecord] = useState(false);

  const [canTakeQuiz, setCanTakeQuiz] = useState(false);

  const [areCommentsHidden, setAreCommentsHidden] = useState(false);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (Object.keys(books).length === 0) return;
    initForm();
  }, [books])

  useEffect(() => {
    getCalendarHeatmap();
  }, [yearAndMonthOfCalendar]);

  useEffect(() => {
    if (selectedReadingRecord === null) return;
    handleShowRecordModal();
  }, [selectedReadingRecord]);

  useEffect(() => {
    setReadingRangeString(getReadingRangeString(readingRange));

    getCoverdVersesReadingRecords();
    getPlan();

    window.scrollTo(0, 0);
  }, [readingRange])

  const init = async () => {
    getAreCommentsHidden();

    await getAllInfo();
    await getUserInfo();
    await getCountOfScripturesToMemorize();

    localStorage.setItem("version", _version);
  }

  const initForm = async () => {
    var date = new Date();
    date = getDateString(date);

    setIsCreating(true);
    setEditingReadingRecordCreateTime(-1);
    setDate(date);
    const newComment = {
      aboutJehovah: "",
      aboutContribute: "",
      aboutApply: "",
      aboutHelp: ""
    }
    setComment(newComment);

    await getCalendarHeatmap();
    await getRecentReadingRecords();
  }

  const getAllInfo = async () => {
    const version = String(localStorage.getItem("version"));

    const getSections = async () => {
      const res = await fetch(getServerUrl() + "Books/sections?userId=" + userId + "&sessionId=" + sessionId);
      const data = await res.json();

      let newSections = {};

      // 連想配列にする
      data.sections.forEach(section => {
        newSections[section.id] = section;
      });

      setSections(newSections);

      const jsonSections = JSON.stringify(newSections);
      localStorage.setItem("sections", jsonSections);
    }

    const getBooks = async () => {
      const res = await fetch(getServerUrl() + "Books/books?userId=" + userId + "&sessionId=" + sessionId);
      const data = await res.json();

      let newBooks = {};

      // 連想配列にする
      data.books.forEach(book => {
        newBooks[book.id] = book;
      });

      setBooks(newBooks);

      const jsonBooks = JSON.stringify(newBooks);
      localStorage.setItem("books", jsonBooks);
    }

    // books
    const jsonBooks = localStorage.getItem("books");
    if (jsonBooks === null || jsonBooks === "undefined") {
      await getBooks();
    }
    else if (version !== _version) {
      await getBooks();
    }
    else {
      const newBooks = JSON.parse(jsonBooks);
      setBooks(newBooks);
    }

    // sections
    const jsonSections = localStorage.getItem("sections");
    if (jsonSections === null || jsonSections === "undefined") {
      await getSections();
    }
    else if (version !== _version) {
      await getSections();
    }
    else {
      const newSections = JSON.parse(jsonSections);
      setSections(newSections);
    }
  }

  const getUserInfo = async () => {
    const res = await fetch(getServerUrl() + "Users/me?userId=" + userId + "&sessionId=" + sessionId);
    const data = await res.json();
    const user = data.user;

    const newUserInfo = {
      howToAnswerQuiz: user.howToAnswerQuiz,
      isQuizTakenBeforeRecord: user.isQuizTakenBeforeRecord,
      themeColor: user.themeColor,
      isOneYearPlanDisplayed: user.isOneYearPlanDisplayed
    };
    setUserInfo(newUserInfo);
  }

  const getCountOfScripturesToMemorize = async () => {
    const res = await fetch(getServerUrl() + "ScripturesToMemorize/range/all?userId=" + userId + "&sessionId=" + sessionId + "&offset=0&limit=100");
    const data = await res.json();
    const count = data.totalCount;

    setCanTakeQuiz(count !== 0);
  }

  const getCoverdVersesReadingRecords = async () => {
    if (readingRange.book === null) return;

    const res = await fetch(getServerUrl() + "ReadingRecords/range/covered_verses?userId=" + userId + "&sessionId=" + sessionId + "&bookId=" + readingRange.book.id + "&chapter=" + readingRange.chapter + "&startVerse=" + readingRange.startVerse + "&endVerse=" + readingRange.endVerse + "&offset=0&limit=100");
    const data = await res.json();
    const readingRecords = data.readingRecords;
    setCoveredVersesReadingRecords(readingRecords);
  }

  const getRecentReadingRecords = async () => {
    const res = await fetch(getServerUrl() + "ReadingRecords/range/all?userId=" + userId + "&sessionId=" + sessionId + "&offset=0&limit=100");
    const data = await res.json();
    const readingRecords = data.readingRecords;
    console.log(readingRecords);

    setRecentReadingRecords(readingRecords);

    const dailyReadingRecords = getDailyFromReadingRecords(readingRecords);
    setRecentDailyReadingRecords(dailyReadingRecords);

    // 通読範囲を設定する
    const newReadingRange = getNextReadingRange((readingRecords.length !== 0) ? readingRecords[0] : null, books);
    setReadingRange(newReadingRange);

    // ゲスト用ユーザー登録アラートの表示を判定する
    if (guest !== null) {
      if (readingRecords.length > 0 && (readingRecords.length % 10 === 0)) {
        setCountOfRecentRecords(readingRecords.length);
        setShowRecommend(true);
      }
      else {
        setShowRecommend(false);
      }
    }
  }

  const getCalendarHeatmap = async () => {
    const date = new Date(yearAndMonthOfCalendar.year, yearAndMonthOfCalendar.month - 1, 1);
    const first = new Date(date.getFullYear(), date.getMonth(), 1);
    const startDate = getDateString(first);
    const last = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const endDate = getDateString(last);

    const res = await fetch(getServerUrl() + "ReadingRecords/heatmap/calendar?userId=" + userId + "&sessionId=" + sessionId + "&startDate=" + startDate + "&endDate=" + endDate);
    const data = await res.json();
    let dayHeatmaps = data.dayHeatmaps;
    dayHeatmaps = dayHeatmaps.map(dayHeatmap => {
      dayHeatmap.date = new Date(dayHeatmap.date);
      return dayHeatmap;
    });
    const weekHeatmaps = getWeekHeatmaps(dayHeatmaps);

    setWeekHeatmaps(weekHeatmaps);
  }

  const handleLogonFromGuest = () => {
    window.location.href = "/logon_from_guest";
  }

  const handleSelectReadingRange = (readingRange) => {
    setReadingRange(readingRange);

    handleCloseReadingRangeSelectionModal();
  }
  const handleShowReadingRangeModal = () => {
    setShowReadingRangeSelectionModal(true);
  }
  const handleCloseReadingRangeSelectionModal = () => {
    setShowReadingRangeSelectionModal(false);
  }

  const handleShowQuizModal = () => {
    setShowQuizModal(true);
  }
  const handleCloseQuizModal = () => {
    setShowQuizModal(false);
  }

  const handleShowRecordModal = () => {
    setShowRecordModal(true);
  }
  const handleCloseRecordModal = () => {
    setShowRecordModal(false);
  }

  const handleOpenReadingRange = () => {
    window.open(getReadingRangeUrl(readingRange), '_blank');
  }

  const handleChangeDate = (event) => {
    setDate(event.target.value);
  }

  const handleChangeAboutJehovah = (event) => {
    const newComment = {
      aboutJehovah: event.target.value
    };
    setComment({ ...comment, ...newComment });
  }

  const handleChangeAboutContribute = (event) => {
    const newComment = {
      aboutContribute: event.target.value
    };
    setComment({ ...comment, ...newComment });
  }

  const handleChangeAboutApply = (event) => {
    const newComment = {
      aboutApply: event.target.value
    };
    setComment({ ...comment, ...newComment });
  }

  const handleChangeAboutHelp = (event) => {
    const newComment = {
      aboutHelp: event.target.value
    };
    setComment({ ...comment, ...newComment });
  }

  const createRecord = async () => {
    const createTime = new Date(Date.now()).toISOString();
    const data = {
      auth: auth,
      readingRecord: {
        createTime: createTime,
        date: date,
        readingRange: {
          bookId: readingRange.book.id,
          chapter: readingRange.chapter,
          startVerse: readingRange.startVerse,
          endVerse: readingRange.endVerse
        },
        comment: {
          aboutJehovah: comment.aboutJehovah,
          aboutContribute: comment.aboutContribute,
          aboutApply: comment.aboutApply,
          aboutHelp: comment.aboutHelp
        }
      }
    };

    try {
      const res = await fetch(getServerUrl() + "ReadingRecords/create", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (!res.ok) {
        const text = await new Response(res.body).text()
        window.alert(text);
        console.error(text);
      }

      await initForm();
    }
    catch (e) {
      window.alert("失敗しました。");
      console.error(e);
    }
  }

  const handleClickCancel = () => {
    initForm();
  }

  const handleClickRecord = () => {
    if (userInfo.isQuizTakenBeforeRecord && canTakeQuiz) {
      setIsRecord(true);
      handleShowQuizModal();
    }
    else {
      createRecord();
    }
  }

  const handleClickUpdateRecord = async () => {
    var result = window.confirm("記録を更新します。本当によろしいですか？");
    if (result) {
      const data = {
        auth: auth,
        readingRecord: {
          createTime: editingReadingRecordCreateTime,
          date: date,
          readingRange: {
            bookId: readingRange.book.id,
            chapter: readingRange.chapter,
            startVerse: readingRange.startVerse,
            endVerse: readingRange.endVerse
          },
          comment: {
            aboutJehovah: comment.aboutJehovah,
            aboutContribute: comment.aboutContribute,
            aboutApply: comment.aboutApply,
            aboutHelp: comment.aboutHelp
          }
        }
      };
      console.log(data);

      try {
        const res = await fetch(getServerUrl() + "ReadingRecords/update", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });

        if (!res.ok) {
          const text = await new Response(res.body).text()
          window.alert(text);
          console.error(text);
        }

        await initForm();
      }
      catch (e) {
        window.alert("失敗しました。\n" + e);
        console.error(e);
      }
    }
  }

  const handleClickDeleteRecord = async () => {
    var result = window.confirm("記録を削除します。本当によろしいですか？");

    if (result) {
      handleCloseRecordModal();
      const data = {
        auth: auth,
        createTime: selectedReadingRecord.createTime
      };
      console.log(data);

      try {
        const res = await fetch(getServerUrl() + "ReadingRecords/delete", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });

        if (!res.ok) {
          const text = await new Response(res.body).text()
          window.alert(text);
          console.error(text);
        }

        await initForm();
      }
      catch (e) {
        window.alert("失敗しました。\n" + e);
        console.error(e);
      }
    }
  }

  const handleClickRecordInQuizModal = () => {
    createRecord();
    handleCloseQuizModal();
  }

  const handleMoveToLastMonth = () => {
    const date = new Date(yearAndMonthOfCalendar.year, yearAndMonthOfCalendar.month - 2, 1);

    const newYearAndMonthOfCalendar = {
      year: date.getFullYear(),
      month: date.getMonth() + 1
    };
    setYearAndMonthOfCalendar(newYearAndMonthOfCalendar);
  }

  const handleMoveToNextMonth = () => {
    const date = new Date(yearAndMonthOfCalendar.year, yearAndMonthOfCalendar.month, 1);

    const newYearAndMonthOfCalendar = {
      year: date.getFullYear(),
      month: date.getMonth() + 1
    };
    setYearAndMonthOfCalendar(newYearAndMonthOfCalendar);
  }

  const handleTakeQuiz = () => {
    setIsRecord(false);
    handleShowQuizModal();
  }

  const handleClickRecentReadingRecord = (readingRecord) => {
    setSelectedReadingRecord(readingRecord);
  }

  const handleClickNextReadingRange = () => {
    handleCloseRecordModal();

    setIsCreating(true);

    // 通読範囲を設定する
    const newReadingRange = getNextReadingRange(selectedReadingRecord, books);
    setReadingRange(newReadingRange);
  }

  const handleClickEditing = () => {
    handleCloseRecordModal();

    const book = books[selectedReadingRecord.readingRange.bookId];
    const date = new Date(selectedReadingRecord.date);

    setIsCreating(false);
    setEditingReadingRecordCreateTime(selectedReadingRecord.createTime);
    setDate(getDateString(date));

    const newReadingRange = {
      book: book,
      chapter: selectedReadingRecord.readingRange.chapter,
      startVerse: selectedReadingRecord.readingRange.startVerse,
      endVerse: selectedReadingRecord.readingRange.endVerse
    }
    setReadingRange(newReadingRange);

    const newComment = {
      aboutJehovah: selectedReadingRecord.comment.aboutJehovah,
      aboutContribute: selectedReadingRecord.comment.aboutContribute,
      aboutApply: selectedReadingRecord.comment.aboutApply,
      aboutHelp: selectedReadingRecord.comment.aboutHelp,
    };
    setComment(newComment);
  }

  const getPlan = async () => {
    if (readingRange.book === null) return;

    const res = await fetch(getServerUrl() + "Plans?userId=" + userId + "&sessionId=" + sessionId + "&bookId=" + readingRange.book.id + "&chapter=" + readingRange.chapter + "&verse=" + readingRange.startVerse);
    const data = await res.json();
    const newPlan = data.plan;
    const planString = getPlanString(newPlan.startBookId, newPlan.startChapter, newPlan.startVerse,
      newPlan.endBookId, newPlan.endChapter, newPlan.endVerse, books);

    setPlan(planString);
  }

  const getAreCommentsHidden = () => {
    if (localStorage.getItem("areCommentsHidden") === null) {
      localStorage.setItem("areCommentsHidden", false);
      setAreCommentsHidden(false);
    }
    else {
      setAreCommentsHidden(JSON.parse(localStorage.getItem("areCommentsHidden")));
    }
  }
  const clickCheckboxOfAreCommentsHidden = (checked) => {
    localStorage.setItem("areCommentsHidden", checked);
    setAreCommentsHidden(checked);
  }

  return (
    <>
      <ReadingRangeSelectionModal
        showModal={showReadingRangeSelectionModal}
        books={books}
        sections={sections}
        initialReadingRange={readingRange}
        recentReadingRecords={recentReadingRecords}
        handleCloseModal={handleCloseReadingRangeSelectionModal}
        handleSelect={handleSelectReadingRange}
      />

      <QuizModal
        showModal={showQuizModal}
        books={books}
        sections={sections}
        userId={userId}
        sessionId={sessionId}
        howToAnswerQuiz={userInfo.howToAnswerQuiz}
        isRecord={isRecord} // 記録前のクイズかどうか
        handleCloseModal={handleCloseQuizModal}
        handleClickRecord={handleClickRecordInQuizModal}
      />

      <RecordModal
        showModal={showRecordModal}
        handleCloseModal={handleCloseRecordModal}
        books={books}
        readingRecord={selectedReadingRecord}
        handleClickNextReadingRange={handleClickNextReadingRange}
        handleClickDeleteRecord={handleClickDeleteRecord}
        handleClickEditing={handleClickEditing}
      />

      <Header />

      <Container className="mt-3 mb-3">
        {showRecommend &&
          <Row className="mb-3">
            <Col>
              <Alert variant="success" onClose={() => setShowRecommend(false)} dismissible>
                <Alert.Heading>通読記録が {countOfRecentRecords} 件に達しました。</Alert.Heading>
                <div className="mb-1">
                  ゲストユーザーは1か月間データの更新がないと自動的にアカウントが削除されます。<br />
                  正規ユーザーとしてアカウント登録するとアカウントが削除されることはありません。
                  正規ユーザーとしてアカウント登録することをご検討ください。
                </div>
                <Stack direction="horizontal">
                  <Button variant="outline-success" onClick={handleLogonFromGuest}>
                    正規ユーザーとしてアカウント登録
                  </Button>
                </Stack>
              </Alert>
            </Col>
          </Row>
        }

        <Row>
          <Col lg={8} md={12}>
            <Form>
              <Row className="align-items-center">
                <Col lg={6} md={6} sm={8} xs={8}>
                  <Stack direction="horizontal" className="mb-3">
                    <div>
                      <Button variant="outline-info" className="gap-book-chapter fs-4" onClick={handleShowReadingRangeModal}><span>{readingRangeString}</span></Button>
                    </div>
                    <div>
                      <Button variant="light" className="gap-left" onClick={handleOpenReadingRange}>開く</Button>
                    </div>
                  </Stack>
                </Col>
                {userInfo.isOneYearPlanDisplayed === true &&
                  <Col lg={3} md={3} sm={4} xs={4}>
                    <Stack direction="horizontal" className="mb-3">
                      計画：{plan}
                    </Stack>
                  </Col>
                }
              </Row>

              <Accordion defaultActiveKey="0" className="mb-3">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>感想を書く</Accordion.Header>
                  <Accordion.Body>
                    <Form.Group className="mb-3">
                      <Form.Label>1. エホバについてどんなことが分かるか</Form.Label>
                      <Form.Control type="text" value={comment.aboutJehovah} onChange={(e) => handleChangeAboutJehovah(e)} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>2. 聖書の中心テーマとどう関係しているか</Form.Label>
                      <Form.Control type="text" value={comment.aboutContribute} onChange={(e) => handleChangeAboutContribute(e)} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>3. 自分の生活でどのように役立てられるか</Form.Label>
                      <Form.Control type="text" value={comment.aboutApply} onChange={(e) => handleChangeAboutApply(e)} />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>4. 人を支え励ますためにどのように使えるか</Form.Label>
                      <Form.Control type="text" value={comment.aboutHelp} onChange={(e) => handleChangeAboutHelp(e)} />
                    </Form.Group>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Stack direction="horizontal" className="mb-4">
                <Stack direction="horizontal" className="me-auto">
                  <Form.Group>
                    <Form.Control type="date" value={date} onChange={(e) => handleChangeDate(e)} size="sm" />
                  </Form.Group>
                </Stack>
                <Stack direction="horizontal">
                  <Button variant="light" onClick={handleClickCancel}>
                    キャンセル
                  </Button>
                  {isCreating ?
                    <>
                      <Button variant="success" className="gap-left" onClick={handleClickRecord} size="lg">
                        記録
                      </Button>
                    </> :
                    <Button variant="success" className="gap-left" onClick={handleClickUpdateRecord}>
                      更新
                    </Button>
                  }
                </Stack>
              </Stack>
            </Form>
          </Col>
          <Col lg={4} md={12}>
            <Row>
              <Col lg={12} md={8}>
                <Card className="mb-2">
                  <Card.Body>
                    <Stack direction="horizontal" className="mb-2 justify-content-center">
                      <Button variant="success" size="sm" className="gap-right" onClick={handleMoveToLastMonth}>
                        <ArrowLeftCircle></ArrowLeftCircle>
                      </Button>
                      {yearAndMonthOfCalendar.year}/{yearAndMonthOfCalendar.month.toString().padStart(2, '0')}
                      <Button variant="success" size="sm" className="gap-left" onClick={handleMoveToNextMonth}>
                        <ArrowRightCircle></ArrowRightCircle>
                      </Button>
                    </Stack>
                    <Stack direction="horizontal" className="justify-content-center">
                      <div className="week guide-text">日</div>
                      <div className="week guide-text">月</div>
                      <div className="week guide-text">火</div>
                      <div className="week guide-text">水</div>
                      <div className="week guide-text">木</div>
                      <div className="week guide-text">金</div>
                      <div className="week guide-text">土</div>
                    </Stack>
                    {weekHeatmaps.map((weekHeatmap, indexWeekHeatmap) => (
                      <Stack key={indexWeekHeatmap} direction="horizontal" className="justify-content-center">
                        {weekHeatmap.map((dayOfWeekHeatmap, indexDayOfWeekHeatmap) => {
                          if (dayOfWeekHeatmap === null) {
                            return (
                              <div key={indexDayOfWeekHeatmap} className="calendar"></div>
                            )
                          }
                          else {
                            return (
                              <OverlayTrigger
                                key={indexDayOfWeekHeatmap}
                                placement="top"
                                overlay={
                                  <Tooltip>
                                    <>
                                      {getDateString(dayOfWeekHeatmap.date, "/")} {dayOfWeekHeatmap.chapterNumber}章（{dayOfWeekHeatmap.verseNumber}節）
                                    </>
                                  </Tooltip>
                                }
                              >
                                {getDateString(new Date()) === getDateString(dayOfWeekHeatmap.date) ?
                                  <div
                                    className="calendar"
                                    style={{ backgroundColor: userInfo.themeColor?.heatmapColors[dayOfWeekHeatmap.colorIndex], border: "solid", borderColor: userInfo.themeColor?.heatmapColors[3] }}
                                  >
                                  </div>
                                  :
                                  <div
                                    className="calendar"
                                    style={{ backgroundColor: userInfo.themeColor?.heatmapColors[dayOfWeekHeatmap.colorIndex] }}
                                  >
                                  </div>
                                }
                              </OverlayTrigger>
                            )
                          }
                        })}
                      </Stack>
                    ))}
                    <Card.Link href='/graph/calendar'>もっと見る</Card.Link>
                  </Card.Body>
                </Card>
              </Col>
              {canTakeQuiz === true &&
                <Col lg={12} md={4}>
                  <Card className="mb-2">
                    <>
                      <Card.Header>
                        クイズ
                      </Card.Header>
                      <Card.Body>
                        <Stack direction="horizontal" className="justify-content-center">
                          <Button variant="success" onClick={handleTakeQuiz}>
                            クイズする
                          </Button>
                        </Stack>
                      </Card.Body>
                    </>
                  </Card>
                </Col>
              }
            </Row>
          </Col>
        </Row>
        {coveredVersesReadingRecords.length !== 0 &&
          <Row className="mt-3">
            <Col>
              {getReadingRangeString(readingRange)} の記録
              <ListGroup>
                {coveredVersesReadingRecords.map((readingRecord, index) => (
                  <ListGroup.Item key={index}>
                    {readingRecord.date.toString().substring(0, 10).replaceAll('-', '/')}
                    &nbsp; &nbsp;
                    <a href={getReadingRangeUrl({
                      book: books[readingRecord.readingRange.bookId],
                      chapter: readingRecord.readingRange.chapter,
                      startVerse: readingRecord.readingRange.startVerse,
                      endVerse: readingRecord.readingRange.endVerse
                    })} target="_blank" rel="noopener noreferrer">
                      {getReadingRangeString({
                        book: books[readingRecord.readingRange.bookId],
                        chapter: readingRecord.readingRange.chapter,
                        startVerse: readingRecord.readingRange.startVerse,
                        endVerse: readingRecord.readingRange.endVerse
                      })}
                    </a>
                    &nbsp; &nbsp;
                    {!areCommentsHidden &&
                      getCommentString(readingRecord.comment)
                    }
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Row>
        }
        {recentDailyReadingRecords?.length === 0 &&
          <Row className="justify-content-center">
            <Col xs="auto" className="mt-5">
              まだ通読記録がありません。
            </Col>
          </Row>
        }
        {(recentDailyReadingRecords !== null && recentDailyReadingRecords?.length !== 0) &&
          <Row className="mt-3">
            <Col>
              <Stack direction="horizontal">
                <div>
                  最近の記録
                  &nbsp; &nbsp;
                  {areCommentsHidden &&
                    <EyeSlashFill></EyeSlashFill>
                  }
                </div>
                <a className="ms-auto" href='/record'>全ての記録を見る</a>
              </Stack>

              {recentDailyReadingRecords?.map((dailyReadingRecords, index) => (
                <Card className="mb-3" key={index}>
                  <Card.Header as="h5">{dailyReadingRecords.date.toString().substring(0, 10).replaceAll('-', '/')}</Card.Header>
                  <ListGroup className="list-group-flush">
                    {dailyReadingRecords.readingRecords.map((readingRecord, readingRecordIndex) => (
                      <ListGroup.Item key={readingRecordIndex}>
                        <Stack direction="horizontal">
                          <div>
                            <a href={getReadingRangeUrl({
                              book: books[readingRecord.readingRange.bookId],
                              chapter: readingRecord.readingRange.chapter,
                              startVerse: readingRecord.readingRange.startVerse,
                              endVerse: readingRecord.readingRange.endVerse
                            })} target="_blank" rel="noopener noreferrer">
                              {getReadingRangeString({
                                book: books[readingRecord.readingRange.bookId],
                                chapter: readingRecord.readingRange.chapter,
                                startVerse: readingRecord.readingRange.startVerse,
                                endVerse: readingRecord.readingRange.endVerse
                              })}
                            </a>
                            &nbsp; &nbsp;
                            {!areCommentsHidden &&
                              getCommentString(readingRecord.comment)
                            }
                          </div>
                          <div className="ms-auto">
                            <Button onClick={() => handleClickRecentReadingRecord(readingRecord)} variant="outline-secondary" size="sm"><PencilSquare></PencilSquare></Button>
                          </div>
                        </Stack>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card>
              ))}
            </Col>
          </Row>
        }

        {(recentDailyReadingRecords !== null && recentDailyReadingRecords?.length !== 0) &&
          <Row className="mt-3">
            <Stack direction="horizontal" className="justify-content-left">
              <ToggleButton
                size="sm"
                id="toggle-hide"
                type="checkbox"
                variant="outline-success"
                checked={areCommentsHidden}
                onChange={(e) => clickCheckboxOfAreCommentsHidden(e.currentTarget.checked)}
              >
                感想を隠す
              </ToggleButton>
            </Stack>
          </Row>
        }
      </Container>
    </>
  )
}
