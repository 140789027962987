import getServerUrl from './getServerUrl';
import getLINERedirectUri from '../functions/getLINERedirectUri';

// authType: "logon", "login", "logonFromGuest" or "add"
export default async function handleClickLINE(authType, setProcessing = f => f) {
  setProcessing(true);
  try {
    const res = await fetch(getServerUrl() + "Auth/line/get_state", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const data = await res.json();
    console.log(data);

    const state = data.state;
    const redirectUri = getLINERedirectUri();
    const url = "https://access.line.me/oauth2/v2.1/authorize?client_id=1657281436&response_type=code&scope=profile%20openid&redirect_uri=" + redirectUri + "&state=" + state

    localStorage.setItem("authType", authType);

    window.location.href = url;
  }
  catch (e) {
    console.error(e);
    setProcessing(false);
  }
}

