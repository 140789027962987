import React, { useState, useEffect } from 'react';
import { Modal, Container, ListGroup, Row, Spinner } from 'react-bootstrap';
import getServerUrl from '../../functions/getServerUrl';
import getCommentString from '../../functions/getCommentString';
import getReadingRangeString from '../../functions/getReadingRangeString';
import getReadingRangeUrl from '../../functions/getReadingRangeUrl';
import getDateString from '../../functions/getDateString';

export default function RecordsOfChapterModal({
  showModal,
  bookId,
  chapter,
  period,
  year,
  handleCloseModal = f => f,
}) {
  const userId = localStorage.getItem("userId");
  const sessionId = localStorage.getItem("sessionId")

  const jsonBooks = localStorage.getItem("books");
  const books = JSON.parse(jsonBooks);

  const [readingRecords, setReadingRecords] = useState(null);

  useEffect(() => {
    if (showModal === true) {
      init();
    }
  }, [showModal]);

  const init = async () => {
    setReadingRecords(null)
    const newReadingRecords = await getReadingRecords();
    setReadingRecords(newReadingRecords);
  }

  const getReadingRecords = async () => {
    let startDate = null;
    let endDate = null;
    if (period === "specificYear") {
      const date = new Date(year, 0, 1);
      startDate = getDateString(new Date(date.getFullYear(), 0, 1));
      endDate = getDateString(new Date(date.getFullYear(), 12, 0));
    }
    const res = await fetch(getServerUrl() + "ReadingRecords/range/same_chapter?userId=" + userId + "&sessionId=" + sessionId
      + "&bookId=" + bookId + "&chapter=" + chapter
      + (startDate !== null ? "&startDate=" + startDate : "")
      + (endDate !== null ? "&endDate=" + endDate : "")
      + "&offset=0&limit=100");
    const data = await res.json();
    const readingRecords = data.readingRecords;
    return readingRecords;
  }

  return (
    <Modal size="lg" show={showModal} onHide={handleCloseModal} >
      <Modal.Header>
        <Modal.Title>
          {bookId !== "" &&
            <>
              {books[bookId].name}{chapter}章の記録
              {period === "specificYear" &&
                "（" + year + "年）"
              }
            </>
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          {readingRecords === null ?
            <Row className="mt-3 mb-3 justify-content-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Row>
            :
            <ListGroup>
              {readingRecords?.map((readingRecord, index) => (
                <ListGroup.Item key={index}>
                  {readingRecord.date.toString().substring(0, 10).replaceAll('-', '/')}
                  &nbsp; &nbsp;
                  <a href={getReadingRangeUrl({
                    book: books[readingRecord.readingRange.bookId],
                    chapter: readingRecord.readingRange.chapter,
                    startVerse: readingRecord.readingRange.startVerse,
                    endVerse: readingRecord.readingRange.endVerse
                  })} target="_blank" rel="noopener noreferrer">
                    {getReadingRangeString({
                      book: books[readingRecord.readingRange.bookId],
                      chapter: readingRecord.readingRange.chapter,
                      startVerse: readingRecord.readingRange.startVerse,
                      endVerse: readingRecord.readingRange.endVerse
                    })}
                  </a>
                  &nbsp; &nbsp;
                  {getCommentString(readingRecord.comment)}
                </ListGroup.Item>
              ))}
            </ListGroup>
          }
        </Container>
      </Modal.Body>
    </Modal>
  )
}

