import React, { Component } from 'react';
import { Container, Row, Button, Modal } from 'react-bootstrap';
import getScriptString from '../../functions/getScriptString';

export class ScriptureToMemorizeModal extends Component {
  render() {
    let scriptString = "";
    let text = "";
    if (this.props.scriptureToMemorize) {
      const scripture = this.props.scriptureToMemorize.scripture;
      const script = scripture.script;
      scriptString = getScriptString(script, this.props.books);
      text = scripture.text;
    }

    return (
      <Modal show={this.props.showModal} onHide={this.props.handleCloseModal}>
        <Modal.Body className="show-grid">
          <Container>
            {this.props.scriptureToMemorize &&
              <>
                <Row>
                  {scriptString}
                </Row>
                <Row>
                  {text}
                </Row>
              </>
            }
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.props.handleClickDelete}>
            削除
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

