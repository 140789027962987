import React, { useState } from 'react';
import { Form, Button, Spinner, Image } from 'react-bootstrap';
import EmailModal from './modals/EmailModal';
import handleClickGoogle from '../functions/handleClickGoogle';
import handleClickLINE from '../functions/handleClickLINE';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Logon() {
  const authType = "logon";
  const [processing, setProcessing] = useState(false);

  const [showEmailModal, setShowEmailModal] = useState(false);

  const handleShowEmailModal = () => {
    setShowEmailModal(true);
  }
  const handleCloseEmailModal = () => {
    setShowEmailModal(false);
  }

  return (
    <>
      <EmailModal
        showModal={showEmailModal}
        authType={authType}
        handleCloseModal={handleCloseEmailModal}
        setProcessing={setProcessing}
      />

      <div className="color-overlay d-flex justify-content-center align-items-center">
        <Form className="form rounded p-4 p-sm-3">

          {processing === true ?
            <div className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
            :
            <>
              <h2 className="mb-3">アカウント登録</h2>

              <Form.Group>
                <Form.Label>メールアドレスでアカウント登録</Form.Label>
              </Form.Group>
              <Button className="mb-4" variant="success" type="button" onClick={handleShowEmailModal} >
                メール認証
              </Button>

              <Form.Group>
                <Form.Label>Googleでアカウント登録</Form.Label>
              </Form.Group>
              <Image className="mb-4 image-button mx-auto" src="btn_google_signin_light_normal_web.png" onClick={() => handleClickGoogle(authType, setProcessing)}></Image>

              <Form.Group>
                <Form.Label>LINEでアカウント登録</Form.Label>
              </Form.Group>
              <Image className="mb-4 image-button mx-auto" src="btn_login_base.png" onClick={() => handleClickLINE(authType, setProcessing)}></Image>

              <div className="mb-3">
                ログインの場合は
                <a href='/login'>こちら</a>
              </div>
              <div>
                <a href='/'>トップに戻る</a>
              </div>
            </>
          }

        </Form>
      </div>
    </>
  )
}
