import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Stack, ListGroup, Pagination, Accordion } from 'react-bootstrap';
import Header from './Header';
import BookModal from './modals/BookModal';
import ChapterModal from './modals/ChapterModal';
import getServerUrl from '../functions/getServerUrl';
import getCommentString from '../functions/getCommentString';
import getReadingRangeString from '../functions/getReadingRangeString';
import getReadingRangeUrl from '../functions/getReadingRangeUrl';
import getDateString from '../functions/getDateString';
import getPlanString from '../functions/getPlanString';

export default function Record() {
  const perPage = 100;

  const userId = localStorage.getItem("userId");
  const sessionId = localStorage.getItem("sessionId")

  const jsonSections = localStorage.getItem("sections");
  const sections = JSON.parse(jsonSections);

  const jsonBooks = localStorage.getItem("books");
  const books = JSON.parse(jsonBooks);

  const initialState = {
    page: 0,
    filter: "none"
  };
  const [state, setState] = useState(initialState);

  const [showBookModal, setShowBookModal] = useState(false);
  const [showChapterModal, setShowChapterModal] = useState(false);
  const [chapterNumber, setChapterNumber] = useState(0);
  const [bookName, setBookName] = useState("-");
  const [selectingRange, setSelectingRange] = useState("start");

  const [accordionHeader, setAccordionHeader] = useState("検索");

  const [startDate, setStartDate] = useState(getDateString(new Date()));
  const [endDate, setEndDate] = useState(getDateString(new Date()));
  const [isFilteredByDate, setIsFilteredByDate] = useState(false);

  const [startBook, setStartBook] = useState(books[1]);
  const [startChapter, setStartChapter] = useState(1);
  const [endBook, setEndBook] = useState(books[1]);
  const [endChapter, setEndChapter] = useState(1);
  const [isFilteredByRange, setIsFilteredByRange] = useState(false);

  const [searchText, setSearchText] = useState("");

  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [readingRecords, setReadingRecords] = useState([]);

  const [startNumber, setStartNumber] = useState(0);
  const [endNumber, setEndNumber] = useState(0);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    init();
  }, [state])

  const init = async () => {
    await getRecords();
  }

  useEffect(() => {
    if (showChapterModal) {
      switch (selectingRange) {
        case "start":
          setChapterNumber(startBook.chapters.length)
          setBookName(startBook.name);
          break;
        case "end":
          setChapterNumber(endBook.chapters.length)
          setBookName(endBook.name);
          break;
      }
    }
  }, [showChapterModal])

  const getRecords = async () => {
    const getAllRecords = async (userId, sessionId, offset, limit) => {
      const res = await fetch(getServerUrl() + "ReadingRecords/range/all?userId=" + userId + "&sessionId=" + sessionId + "&offset=" + offset + "&limit=" + limit);
      const data = await res.json();
      return data;
    }
    const getRecordsOfSearch = async (userId, sessionId, startDate, endDate, startBook, startChapter, endBook, endChapter, searchText, offset, limit) => {
      const queryParameter = `userId=${userId}&sessionId=${sessionId}&text=${searchText}&offset=${offset}&limit=${limit}`
        + (isFilteredByDate ? `&startDate=${startDate}&endDate=${endDate}` : "")
        + (isFilteredByRange ? `&startBookId=${startBook.id}&startChapter=${startChapter}&endBookId=${endBook.id}&endChapter=${endChapter}` : "");

      const res = await fetch(getServerUrl() + "ReadingRecords/search?" + queryParameter);
      const data = await res.json();
      return data;
    }

    const offset = (state.page) * perPage;
    let newReadingRecords = [];
    let newCount = -1;
    let data;
    switch (state.filter) {
      case "search":
        data = await getRecordsOfSearch(userId, sessionId, startDate, endDate, startBook, startChapter, endBook, endChapter, searchText, offset, perPage);
        newReadingRecords = data.readingRecords;
        newCount = data.totalCount;

        let header = "";

        if (isFilteredByDate) {
          header += `日付:${getDateString(new Date(startDate), '/')}${startDate !== endDate ? `-${getDateString(new Date(endDate), '/')}` : ''}`;
        }

        if (isFilteredByRange) {
          header += `${header ? '。　' : ''}範囲:${getPlanString(startBook.id, startChapter, null, endBook.id, endChapter, null, books)}`;
        }

        if (searchText.trim()) {
          header += `${header ? '。　' : ''}感想:「${searchText.trim()}」`;
        }
        setAccordionHeader(`検索条件⇒${header}`);
        break;
      default:
        data = await getAllRecords(userId, sessionId, offset, perPage);
        newReadingRecords = data.readingRecords;
        newCount = data.totalCount;
        setAccordionHeader("検索");
        break;
    }

    const newStartNumber = newReadingRecords.length !== 0 ? offset + 1 : 0;
    const newEndNumber = newReadingRecords.length !== 0 ? newStartNumber + newReadingRecords.length - 1 : 0;

    setReadingRecords(newReadingRecords);
    setStartNumber(newStartNumber);
    setEndNumber(newEndNumber);

    const newPageNumber = newCount === 0 ? 0 : Math.floor((newCount - 1) / perPage);

    setCount(newCount);
    setPageNumber(newPageNumber);
  }

  const handleKeyDownSearchText = (event) => {
    if (event.nativeEvent.isComposing || event.key !== 'Enter') return;
    handleClickFilterBySearch();
  }
  const handleChangeSearchText = (event) => {
    setSearchText(event.target.value);
  }
  const handleClickFilterBySearch = async () => {
    const newState = {
      filter: "search",
      page: 0
    };
    setState({ ...state, ...newState });
  }

  const handleClickFirstPage = async () => {
    const newState = {
      page: 0
    };
    setState({ ...state, ...newState });
  }

  const handleClickPrevPage = async () => {
    const newPage = state.page > 0 ? state.page - 1 : 0;
    const newState = {
      page: newPage
    };
    setState({ ...state, ...newState });
  }

  const handleClickNextPage = async () => {
    const newPage = state.page < pageNumber ? state.page + 1 : pageNumber;
    const newState = {
      page: newPage
    };
    setState({ ...state, ...newState });
  }

  const handleClickLastPage = async () => {
    const newState = {
      page: pageNumber
    };
    setState({ ...state, ...newState });
  }

  const handleChangeStartDate = (event) => {
    setStartDate(event.target.value);

    // 日付の前後関係が保たれるようendのほうも値を変える
    if (new Date(event.target.value) > new Date(endDate)) {
      setEndDate(event.target.value);
    }
  }

  const handleChangeEndDate = (event) => {
    // 日付の前後関係が保たれるようstartのほうも値を変える
    if (new Date(startDate) > new Date(event.target.value)) {
      setStartDate(event.target.value);
    }

    setEndDate(event.target.value);
  }

  const handleSelectBook = (book) => {
    switch (selectingRange) {
      case "start":
        setStartBook(book);
        setStartChapter(1);

        // 範囲の前後関係が保たれるようendのほうも値を変える
        if (book.id > endBook.id) {
          setEndBook(book);
          setEndChapter(1);
        }
        break;
      case "end":
        // 範囲の前後関係が保たれるようstartのほうも値を変える
        if (startBook.id > book.id) {
          setStartBook(book);
          setStartChapter(book.chapters.length);
        }

        setEndBook(book);
        setEndChapter(book.chapters.length);
        break;
    }

    handleCloseBookModal();
    handleShowChapterModal(selectingRange);
  }
  const handleCloseBookModal = () => {
    setShowBookModal(false);
  }

  const handleSelectChapter = (chapter) => {
    switch (selectingRange) {
      case "start":
        setStartChapter(chapter);
        if (startBook.id === endBook.id && chapter > endChapter) {
          setEndBook(startBook);
          setEndChapter(chapter);
        }
        break;
      case "end":
        if (startBook.id === endBook.id && startChapter > chapter) {
          setStartChapter(chapter);
        }
        setEndChapter(chapter);
        break;
    }

    handleCloseChapterModal();
  }

  const handleShowChapterModal = () => {
    setShowChapterModal(true);
  }
  const handleCloseChapterModal = () => {
    setShowChapterModal(false);
  }

  const handleClickStartBook = () => {
    setSelectingRange("start");
    setShowBookModal(true);
  }

  const handleClickStartChapter = () => {
    setSelectingRange("start");
    handleShowChapterModal(selectingRange);
  }

  const handleClickEndBook = () => {
    setSelectingRange("end");
    setShowBookModal(true);
  }

  const handleClickEndChapter = () => {
    setSelectingRange("end");
    handleShowChapterModal(selectingRange);
  }

  const handleChangeFilterByDate = () => {
    setIsFilteredByDate(!isFilteredByDate);
  }

  const handleChangeFilterByRange = () => {
    setIsFilteredByRange(!isFilteredByRange);
  }

  const handleClickClear = () => {
    setIsFilteredByDate(false);
    setIsFilteredByRange(false);
    setSearchText("");

    const newState = {
      filter: "none",
      page: 0
    };
    setState({ ...state, ...newState });
  }

  return (
    <>
      <BookModal
        showModal={showBookModal}
        books={books}
        sections={sections}
        handleCloseModal={handleCloseBookModal}
        handleSelect={handleSelectBook}
      />

      <ChapterModal
        showModal={showChapterModal}
        chapterNumber={chapterNumber}
        handleCloseModal={handleCloseChapterModal}
        handleSelect={handleSelectChapter}
        bookName={bookName}
      />

      <Header />

      <Container className="mt-4 mb-3">
        <Row>
          <Accordion className='mb-4'>
            <Accordion.Item eventKey="0">
              <Accordion.Header>{accordionHeader}</Accordion.Header>
              <Accordion.Body>
                <Row className="align-items-center mb-4">
                  <Col md={3} sm={12} xs={12}>
                    <Form.Check
                      className="gap-left"
                      type="checkbox"
                      label="日付を指定する"
                      id="filterByDate"
                      checked={isFilteredByDate}
                      onChange={handleChangeFilterByDate}
                    />
                  </Col>
                  <Col md={4} sm={5} xs={12}>
                    <Form.Control type="date" disabled={!isFilteredByDate} value={startDate} onChange={(e) => handleChangeStartDate(e)} size="xs" />
                  </Col>
                  <Col className="text-center" md={1} sm={2} xs={12}>
                    ~
                  </Col>
                  <Col md={4} sm={5} xs={12}>
                    <Form.Control type="date" disabled={!isFilteredByDate} value={endDate} onChange={(e) => handleChangeEndDate(e)} size="xs" />
                  </Col>
                </Row>
                <Row className="align-items-center mb-4">
                  <Col md={3} sm={12} xs={12}>
                    <Form.Check
                      className="gap-left"
                      type="checkbox"
                      label="範囲を指定する"
                      id="filterByRange"
                      checked={isFilteredByRange}
                      onChange={handleChangeFilterByRange}
                    />
                  </Col>
                  <Col md={4} sm={5} xs={12} className="d-flex justify-content-center">
                    <Stack direction="horizontal">
                      <Button disabled={!isFilteredByRange} variant="outline-info" className="gap-book-chapter fs-4" onClick={handleClickStartBook}>{startBook.name}</Button>
                      <Button disabled={!isFilteredByRange} variant="outline-info" className="gap-book-chapter fs-4" onClick={handleClickStartChapter}>{startChapter}</Button>
                      {startBook.id === 19 ? "編" : "章"}
                    </Stack>
                  </Col>
                  <Col className="text-center" md={1} sm={2} xs={12}>
                    ~
                  </Col>
                  <Col md={4} sm={5} xs={12} className="d-flex justify-content-center">
                    <Stack direction="horizontal">
                      <Button disabled={!isFilteredByRange} variant="outline-info" className="gap-book-chapter fs-4" onClick={handleClickEndBook}>{endBook.name}</Button>
                      <Button disabled={!isFilteredByRange} variant="outline-info" className="gap-book-chapter fs-4" onClick={handleClickEndChapter}>{endChapter}</Button>
                      {endBook.id === 19 ? "編" : "章"}
                    </Stack>
                  </Col>
                </Row>

                <Form.Group as={Row} className="mb-4">
                  <Form.Label column md="1" sm="2" xs="3">感想</Form.Label>
                  <Col md="11" sm="10" xs="9">
                    <Form.Control type="text" value={searchText} onChange={(e) => handleChangeSearchText(e)} onKeyDown={(e) => handleKeyDownSearchText(e)} />
                  </Col>
                </Form.Group>

                <Stack direction="horizontal" className="justify-content-end">
                  <Button variant="light" onClick={handleClickClear}>
                    クリア
                  </Button>
                  <Button variant="success" className="gap-left" onClick={handleClickFilterBySearch}>
                    検索
                  </Button>
                </Stack>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            {startNumber} - {endNumber} 件 （全 {count} 件）
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <Pagination>
              <Pagination.First onClick={handleClickFirstPage} />
              <Pagination.Prev onClick={handleClickPrevPage} />
              <Pagination.Next onClick={handleClickNextPage} />
              <Pagination.Last onClick={handleClickLastPage} />
            </Pagination>
          </Col>
        </Row>
        <Row>
          <Col>
            <ListGroup>
              {readingRecords.map((readingRecord, index) => (
                <ListGroup.Item key={index}>
                  {readingRecord.date.toString().substring(0, 10).replaceAll('-', '/')}
                  &nbsp; &nbsp;
                  <a href={getReadingRangeUrl({
                    book: books[readingRecord.readingRange.bookId],
                    chapter: readingRecord.readingRange.chapter,
                    startVerse: readingRecord.readingRange.startVerse,
                    endVerse: readingRecord.readingRange.endVerse
                  })} target="_blank" rel="noopener noreferrer">
                    {getReadingRangeString({
                      book: books[readingRecord.readingRange.bookId],
                      chapter: readingRecord.readingRange.chapter,
                      startVerse: readingRecord.readingRange.startVerse,
                      endVerse: readingRecord.readingRange.endVerse
                    })}
                  </a>
                  &nbsp; &nbsp;
                  {getCommentString(readingRecord.comment)}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      </Container >
    </>
  )
}