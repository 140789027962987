import getServerUrl from './getServerUrl';

// authType: "logon", "login", "logonFromGuest" or "add"
export default async function handleClickEmail(
  email,
  authType,
  setProcessing = f => f,
  handleCloseModal = f => f,
) {
  setProcessing(true);
  handleCloseModal();

  try {
    const data = {
      address: email
    };
    const res = await fetch(getServerUrl() + "Auth/email/send_passcode", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    if (res.status === 200) {
      localStorage.setItem("authType", authType);
      sessionStorage.setItem("email", email);

      window.location.href = "/email/check";
    }
  }
  catch (e) {
    console.error(e);
    setProcessing(false);
  }
}