import React, { useState, useEffect } from 'react';
import RecordsOfChapterModal from './modals/RecordsOfChapterModal';
import { Container, Row, Col, Button, Stack, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ArrowLeftCircle, ArrowRightCircle } from 'react-bootstrap-icons';
import Header from './Header';
import getServerUrl from '../functions/getServerUrl';
import getDateString from '../functions/getDateString';
import './style.css';

export default function GraphChapter() {
  const [themeColor, setThemeColor] = useState(null);
  const [bookHeatmapsPerSections, setBookHeatmapsPerSections] = useState([]);
  const [period, setPeriod] = useState("specificYear");
  const [year, setYear] = useState(new Date().getFullYear());
  const [showRecordsModal, setShowRecordsModal] = useState(false);
  const [bookIdAndChapter, setBookIdAndChapter] = useState({
    bookId: "",
    chapter: ""
  });

  const userId = localStorage.getItem("userId");
  const sessionId = localStorage.getItem("sessionId")

  const jsonSections = localStorage.getItem("sections");
  const sections = JSON.parse(jsonSections);

  const jsonBooks = localStorage.getItem("books");
  const books = JSON.parse(jsonBooks);

  const getUserInfo = async (userId, sessionId) => {
    const res = await fetch(getServerUrl() + "Users/me?userId=" + userId + "&sessionId=" + sessionId);
    const data = await res.json();
    const user = data.user;
    if (user !== undefined) {
      setThemeColor(user.themeColor);
    }
  }

  const getBookHeatmaps = async (userId, sessionId) => {
    let data;
    if (period === "whole") {
      const res = await fetch(getServerUrl() + "ReadingRecords/heatmap/chapter?userId=" + userId + "&sessionId=" + sessionId);
      data = await res.json();
    }
    else {
      const date = new Date(year, 0, 1);
      const startDate = getDateString(new Date(date.getFullYear(), 0, 1));
      const endDate = getDateString(new Date(date.getFullYear(), 12, 0));

      const res = await fetch(getServerUrl() + "ReadingRecords/heatmap/chapter?userId=" + userId + "&sessionId=" + sessionId + "&startDate=" + startDate + "&endDate=" + endDate);
      data = await res.json();
    }

    const bookHeatmaps = data.bookHeatmaps;

    const sectionList = Object.values(sections);
    const bookList = Object.values(books);
    let bookHeatmapsPerSections = [];
    sectionList.forEach(section => {
      const bookIdsOfSection = bookList.filter(book => book.sectionId === section.id).map(book => book.id);
      const bookHeatmapsOfSection = bookHeatmaps.filter(bookHeatmap => bookIdsOfSection.includes(bookHeatmap.bookId))
        .map(bookHeatmap => (
          {
            book: books[bookHeatmap.bookId],
            chapterHeatmaps: bookHeatmap.chapterHeatmaps
          }
        ))
      bookHeatmapsPerSections.push({
        section: section,
        bookHeatmapsOfSection: bookHeatmapsOfSection
      });
    });
    setBookHeatmapsPerSections(bookHeatmapsPerSections);
  }

  const handleClickSpecificYear = () => {
    setPeriod("specificYear");
    setBookHeatmapsPerSections([]);
  }

  const handleClickWholePeriod = () => {
    setPeriod("whole");
    setBookHeatmapsPerSections([]);
  }

  const handleMoveToLastYearOfChapterHeatmap = () => {
    setYear(year - 1);
    setBookHeatmapsPerSections([]);
  }

  const handleMoveToNextYearOfChapterHeatmap = () => {
    setYear(year + 1);
    setBookHeatmapsPerSections([]);
  }

  const handleClickGraph = (bookId, chapter) => {
    setBookIdAndChapter(
      {
        bookId: bookId,
        chapter: chapter
      }
    );
  }

  useEffect(() => {
    getUserInfo(userId, sessionId);
    getBookHeatmaps(userId, sessionId);
  }, []);

  useEffect(() => {
    getBookHeatmaps(userId, sessionId);
  }, [period, year]);

  useEffect(() => {
    if (bookIdAndChapter.bookId !== "") {
      handleShowRecordsModal();
    }
  }, [bookIdAndChapter])

  const handleShowRecordsModal = () => {
    setShowRecordsModal(true);
  }
  const handleCloseRecordsModal = () => {
    setShowRecordsModal(false);
    setBookIdAndChapter(
      {
        bookId: "",
        chapter: ""
      }
    );
  }

  return (
    <>
      <RecordsOfChapterModal
        showModal={showRecordsModal}
        bookId={bookIdAndChapter.bookId}
        chapter={bookIdAndChapter.chapter}
        period={period}
        year={year}
        handleCloseModal={handleCloseRecordsModal}
      />

      <Header />

      <Container className="mt-3">
        <Row>
          <h6>
            期間：
            {period === "whole" ?
              <>
                全期間
              </>
              :
              <>
                {year}年
              </>
            }
          </h6>
        </Row>
        <Row>
          <Stack direction="horizontal" className="mb-1 justify-content-center">
            {period === "whole" ?
              <Button variant="success" size="sm" onClick={handleClickSpecificYear}>年ごと</Button>
              :
              <>
                <Button variant="success" size="sm" className="gap-right" onClick={handleClickWholePeriod}>全期間</Button>
                <Button variant="success" size="sm" className="gap-left gap-right" onClick={handleMoveToLastYearOfChapterHeatmap}>
                  <ArrowLeftCircle></ArrowLeftCircle>
                </Button>
                {year}
                <Button variant="success" size="sm" className="gap-left" onClick={handleMoveToNextYearOfChapterHeatmap}>
                  <ArrowRightCircle></ArrowRightCircle>
                </Button>
              </>
            }
          </Stack>
        </Row>
        {bookHeatmapsPerSections.length === 0 &&
          <Row className="mt-3 mb-3 justify-content-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Row>
        }
        {bookHeatmapsPerSections.map((bookHeatmapsOfSection, index) => (
          <div key={index}>
            <Row className="mt-1">
              <Col>
                {bookHeatmapsOfSection.section.name}
              </Col>
            </Row>
            <Row>
              <Col>
                {bookHeatmapsOfSection.bookHeatmapsOfSection.map((bookHeatmap, indexBookHeatmap) => (
                  <span key={indexBookHeatmap}>
                    <div className="item chapter-book-name"><small>{bookHeatmap.book.shortName}</small></div>
                    {bookHeatmap.chapterHeatmaps.map((chapterHeatmap, indexChapterHeatmap) => (
                      <OverlayTrigger
                        key={indexChapterHeatmap}
                        placement="top"
                        overlay={
                          <Tooltip>
                            <>
                              {bookHeatmap.book.name}{chapterHeatmap.chapter}章 {chapterHeatmap.count}回
                            </>
                          </Tooltip>
                        }
                      >
                        <div
                          key={indexChapterHeatmap}
                          className="item chapter"
                          style={{ backgroundColor: themeColor?.heatmapColors[chapterHeatmap.colorIndex] }}
                          onClick={() => handleClickGraph(bookHeatmap.book.id, chapterHeatmap.chapter)}
                        >
                        </div>
                      </OverlayTrigger>
                    ))}
                  </span>
                ))}
              </Col>
            </Row>
          </div>
        ))}
        <Row>
          <Col>
            {bookHeatmapsPerSections.length !== 0 &&
              <Stack direction="horizontal" className="mt-3 mb-3 justify-content-end">
                <div className="chapter guide-text">少</div>
                {themeColor?.heatmapColors.map((heatmapColor, index) => (
                  <div key={index} className="chapter" style={{ backgroundColor: heatmapColor }}></div>
                ))}
                <div className="chapter guide-text">多</div>
              </Stack>
            }
          </Col>
        </Row>
      </Container>
    </>
  )
}
