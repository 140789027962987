import React from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';

export default function VerseModal({
  showModal,
  bookName,
  chapter,
  startVerse,
  endVerse,
  handleCloseModal = f => f,
  handleSelect = f => f
}) {
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header>
        <Modal.Title>{bookName} {chapter}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <Row xs={6}>
            {Array.from(Array(endVerse - startVerse + 1).keys()).map(x => x + startVerse).map((verse) => (
              <Col key={verse} className="mb-3 d-grid">
                <Button variant="outline-info" size="sm" onClick={() => handleSelect(verse)}>{verse}</Button>
              </Col>
            ))}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  )
}