export default function getDailyFromReadingRecords(readingRecords) {
    // 画面に日ごとに表示するために、日単位のデータにする
    // 描画しやすいようにリスト形式で返す
    let dailyReadingRecords = {};
    readingRecords.forEach(readingRecord => {
        if (!(readingRecord.date in dailyReadingRecords)) {
            dailyReadingRecords[readingRecord.date] = [];
        }
        dailyReadingRecords[readingRecord.date].push(readingRecord);
    });

    const keys = Object.keys(dailyReadingRecords);
    keys.sort().reverse();
    let list = [];
    keys.forEach(key => {
        list.push({
            "date": key,
            "readingRecords": dailyReadingRecords[key]
        })
    });

    return list;
}