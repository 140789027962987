export default function getNextVerse(book, chapter, verse, books) {
  // 節が最終節でなければ節を次に進める
  if (verse < book.chapters[chapter - 1].endVerse) {
    // 省略節は飛ばす
    if (book.chapters[chapter - 1].omittedVerses.includes(verse + 1)) {
      return getNextVerse(book, chapter, verse + 1, books);
    }

    return {
      book: book,
      chapter: chapter,
      verse: verse + 1
    };
  }

  // 節が最終節なら次の章に進む
  // 章が最終章でなければ章を次に進める
  if (chapter < book.chapters.length) {
    return {
      book: book,
      chapter: chapter + 1,
      verse: book.chapters[chapter].startVerse,
    };
  }

  // 次の書に進む
  if (book.id < Object.keys(books).length) {
    return {
      book: books[book.id + 1],
      chapter: 1,
      verse: books[book.id + 1].chapters[0].startVerse,
    };
  }

  // 最後の書の場合は最初の書に戻る
  return {
    book: books[1],
    chapter: 1,
    verse: books[1].chapters[0].startVerse,
  };
}