import getNextVerse from './getNextVerse';
import isReadingRangeWholeChpter from './isReadingRangeWholeChpater';

// 通読範囲を返す
// 基本は最新の記録の次の章を設定する
// ただし最新の記録が1節だけの場合は、次の節を設定する
// 記録がない場合は創世記1章を設定する
export default function getNextReadingRange(readingRecord, books) {
  if (readingRecord === null) {
    const book = books[1];

    const result = {
      book: book,
      chapter: 1,
      startVerse: book.chapters[0].startVerse,
      endVerse: book.chapters[0].endVerse
    };
    return result;
  }

  // 最新の記録が1章全部なら次の章全部を指定する
  if (isReadingRangeWholeChpter({
    book: books[readingRecord.readingRange.bookId],
    chapter: readingRecord.readingRange.chapter,
    startVerse: readingRecord.readingRange.startVerse,
    endVerse: readingRecord.readingRange.endVerse
  })) {
    const { book, chapter, verse } = getNextVerse(books[readingRecord.readingRange.bookId], readingRecord.readingRange.chapter, readingRecord.readingRange.endVerse, books);
    const result = {
      book: book,
      chapter: chapter,
      startVerse: verse,
      endVerse: book.chapters[chapter - 1].endVerse
    };
    return result;
  }

  // 最新の記録が1章全部じゃなければ同じ節数を指定する
  const length = readingRecord.readingRange.endVerse - readingRecord.readingRange.startVerse;
  const { book, chapter, verse } = getNextVerse(books[readingRecord.readingRange.bookId], readingRecord.readingRange.chapter, readingRecord.readingRange.endVerse, books);
  const result = {
    book: book,
    chapter: chapter,
    startVerse: verse,
    endVerse: Math.min(verse + length, book.chapters[chapter - 1].endVerse)
  };
  return result;
}