import React, { useEffect } from 'react';
import getServerUrl from '../functions/getServerUrl';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Logout() {
  const handleLogout = async (userId, sessionId) => {
    localStorage.removeItem("userId");
    localStorage.removeItem("sessionId");
    localStorage.removeItem("guest");

    try {
      await fetch(getServerUrl() + "Users/logout?userId=" + userId + "&sessionId=" + sessionId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      window.location.href = "/login";
    }
    catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const sessionId = localStorage.getItem("sessionId")

    handleLogout(userId, sessionId);
  }, []);

  return (
    <p>ログアウトしています。ログアウトしたら自動でページを移動します。</p>
  )
}
