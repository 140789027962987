import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';

export default function BookModal({
  showModal,
  sections,
  books,
  handleCloseModal = f => f,
  handleSelect = f => f
}) {
  const [booksPerSections, setBooksPerSections] = useState([]);

  useEffect(() => {
    let booksPerSections = [];
    Object.values(sections).forEach(section => {
      const booksOfSection = Object.values(books).filter(book => book.sectionId === section.id);
      booksPerSections.push({
        section: section,
        books: booksOfSection
      });
    });
    setBooksPerSections(booksPerSections);
  }, [sections, books]);

  return (
    <Modal size="lg" show={showModal} onHide={handleCloseModal}>
      <Modal.Body className="show-grid">
        <Container>
          {booksPerSections.map((booksPerSection, indexSection) => (
            <div key={indexSection}>
              <Row className="mt-2">{booksPerSection.section.name}</Row>
              <Row xl={6} lg={5} md={3} sm={3} xs={2}>
                {booksPerSection.books.map((book, indexBook) => (
                  <Col key={indexBook} className="mb-1 d-grid">
                    <Button variant="outline-info" size="sm" onClick={() => handleSelect(book)}>{book.name}</Button>
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  )
}