import React, { useState, useEffect } from 'react';
import { Modal, Container, ListGroup, Row, Spinner } from 'react-bootstrap';
import getServerUrl from '../../functions/getServerUrl';
import getCommentString from '../../functions/getCommentString';
import getReadingRangeString from '../../functions/getReadingRangeString';
import getReadingRangeUrl from '../../functions/getReadingRangeUrl';

export default function RecordsOfDateModal({
  showModal,
  date,
  handleCloseModal = f => f,
}) {
  const userId = localStorage.getItem("userId");
  const sessionId = localStorage.getItem("sessionId")

  const jsonBooks = localStorage.getItem("books");
  const books = JSON.parse(jsonBooks);

  const [readingRecords, setReadingRecords] = useState(null);

  useEffect(() => {
    if (showModal === true) {
      init();
    }
  }, [showModal]);

  const init = async () => {
    setReadingRecords(null)
    const newReadingRecords = await getReadingRecords();
    setReadingRecords(newReadingRecords);
  }

  const getReadingRecords = async () => {
    const res = await fetch(getServerUrl() + "ReadingRecords/range/same_date?userId=" + userId + "&sessionId=" + sessionId + "&date=" + date + "&offset=0&limit=100");
    const data = await res.json();
    const readingRecords = data.readingRecords;
    return readingRecords;
  }

  return (
    <Modal size="lg" show={showModal} onHide={handleCloseModal} >
      <Modal.Header>
        <Modal.Title>
          {date !== "" &&
            <>
              {date.replaceAll('-', '/')}の記録
            </>
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          {readingRecords === null ?
            <Row className="mt-3 mb-3 justify-content-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Row>
            :
            <ListGroup>
              {readingRecords?.map((readingRecord, index) => (
                <ListGroup.Item key={index}>
                  <a href={getReadingRangeUrl({
                    book: books[readingRecord.readingRange.bookId],
                    chapter: readingRecord.readingRange.chapter,
                    startVerse: readingRecord.readingRange.startVerse,
                    endVerse: readingRecord.readingRange.endVerse
                  })} target="_blank" rel="noopener noreferrer">
                    {getReadingRangeString({
                      book: books[readingRecord.readingRange.bookId],
                      chapter: readingRecord.readingRange.chapter,
                      startVerse: readingRecord.readingRange.startVerse,
                      endVerse: readingRecord.readingRange.endVerse
                    })}
                  </a>
                  &nbsp; &nbsp;
                  {getCommentString(readingRecord.comment)}
                </ListGroup.Item>
              ))}
            </ListGroup>
          }
        </Container>
      </Modal.Body>
    </Modal>
  )
}
