import { RouterConfig } from './route/Route';

function App() {
  return (
    <div>
      <RouterConfig />
    </div>
  );
}

export default App;
