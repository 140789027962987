import getNextReadingRange from "./getNextReadingRange";
import getNextVerse from "./getNextVerse";

// 次の通読範囲の候補を返す
// 実装方針
    // 引数の通読記録リストをたどり（リストがソートされていると想定する）
    // 通読範囲と連続しているか判定していく
    // 連続していなければ「最新の通読記録リスト」に追加していく
    // そのようにして、通読範囲が連続している最新の記録リストを作成する
    // 最新の記録リストの各要素において次の通読範囲を計算する
export default function getReadingRangeCandidates(readingRecords, books) {
    if (readingRecords.length === 0) {
        // 通読記録がない時は空配列を返す
        return [];
    }

    // 連続している最新の通読記録を作成する
    let series = [{
        latest: readingRecords[0],
        earliest: readingRecords[0]
    }];
    for (let i = 1; i < readingRecords.length; i++) {
        for (let j = 0; j < series.length; j++) {
            if (isContinuous(readingRecords[i], series[j].earliest, books)) {
                series[j].earliest = readingRecords[i];
                break;
            }
            if (j === series.length - 1) {
                series.push({
                    latest: readingRecords[i],
                    earliest: readingRecords[i]
                });
                break;
            }
        }
    }

    // 最新の通読記録の次の通読範囲を作成する
    const candidates = series.map((element) => {
        return {
            readingRange: getNextReadingRange(element.latest, books),
            date: element.latest.date,
        }
    });

    return candidates;
}

function isContinuous(before, after, books) {
    const beforeReadingRange = before.readingRange;
    const next = getNextVerse(books[beforeReadingRange.bookId], beforeReadingRange.chapter, beforeReadingRange.endVerse, books);

    const afterReadingRange = after.readingRange;
    if (next.book.id === afterReadingRange.bookId && next.chapter === afterReadingRange.chapter && next.verse === afterReadingRange.startVerse) {
        return true;
    }
    return false;
}