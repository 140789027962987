import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Stack, ListGroup, Accordion, Pagination, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';
import Header from './Header';
import BookModal from './modals/BookModal';
import ChapterModal from './modals/ChapterModal';
import VerseModal from './modals/VerseModal';
import { ScriptureToMemorizeModal } from './modals/ScriptureToMemorizeModal';
import getServerUrl from '../functions/getServerUrl';
import getScriptString from '../functions/getScriptString';
import './style.css';

export default function Memory() {
  const perPage = 100;
  const [page, setPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [startNumber, setStartNumber] = useState(0);
  const [endNumber, setEndNumber] = useState(0);
  const [showBookModal, setShowBookModal] = useState(false);
  const [bookId, setBookId] = useState(0);
  const [bookName, setBookName] = useState("-");
  const [showChapterModal, setShowChapterModal] = useState(false);
  const [chapterNumber, setChapterNumber] = useState(0);
  const [chapter, setChapter] = useState("-");
  const [showVerseModal, setShowVerseModal] = useState(false);
  const [startVerse, setStartVerse] = useState(0);
  const [endVerse, setEndVerse] = useState(0);
  const [verse, setVerse] = useState("-");
  const [text, setText] = useState("");
  const [scripturesToMemorize, setScripturesToMemorize] = useState([]);
  const [showScriptureToMemorizeModal, setShowScriptureToMemorizeModal] = useState(false);
  const [selectedScriptureToMemorize, setSelectedScriptureToMemorize] = useState(null);
  const [sort, setSort] = useState("Recent");

  const userId = localStorage.getItem("userId");
  const sessionId = localStorage.getItem("sessionId")
  const auth = {
    userId: userId,
    sessionId: sessionId
  };

  const jsonSections = localStorage.getItem("sections");
  const sections = JSON.parse(jsonSections);

  const jsonBooks = localStorage.getItem("books");
  const books = JSON.parse(jsonBooks);

  useEffect(() => {
    const book = books[1];
    setBookId(book.id);
    setBookName(book.name);
    setChapterNumber(book.chapters.length);
    setChapter(1);
    setStartVerse(book.chapters[0].startVerse);
    setEndVerse(book.chapters[0].endVerse);
    setVerse(book.chapters[0].startVerse);

    getScripturesToMemorize();
  }, []);

  useEffect(() => {
    getScripturesToMemorize();
  }, [page, sort]);

  const getScripturesToMemorize = async () => {
    const offset = page * perPage;

    const res = await fetch(getServerUrl() + "ScripturesToMemorize/range/all?userId=" + userId + "&sessionId=" + sessionId + "&offset=" + offset + "&limit=" + perPage + "&sort=" + sort);
    const data = await res.json();
    const newScripturesToMemorize = data.scripturesToMemorize;
    const newCount = data.totalCount;

    const newStartNumber = newScripturesToMemorize.length !== 0 ? offset + 1 : 0;
    const newEndNumber = newScripturesToMemorize.length !== 0 ? newStartNumber + newScripturesToMemorize.length - 1 : 0;

    setScripturesToMemorize(newScripturesToMemorize);
    setStartNumber(newStartNumber);
    setEndNumber(newEndNumber);

    const newPageNumber = newCount === 0 ? 0 : Math.floor((newCount - 1) / perPage);

    setCount(newCount);
    setPageNumber(newPageNumber);
  }

  const handleSelectBook = (book) => {
    setBookId(book.id);
    setBookName(book.name);
    setChapterNumber(book.chapters.length);
    setChapter(1);
    setStartVerse(book.chapters[0].startVerse);
    setEndVerse(book.chapters[0].endVerse);
    setVerse(1);

    handleCloseBookModal();
    handleShowChapterModal();
  }
  const handleShowBookModal = () => {
    setShowBookModal(true);
  }
  const handleCloseBookModal = () => {
    setShowBookModal(false);
  }

  const handleSelectChapter = (chapter) => {
    const book = books[bookId];
    setChapter(chapter);
    setStartVerse(book.chapters[chapter - 1].startVerse);
    setEndVerse(book.chapters[chapter - 1].endVerse);
    setVerse(book.chapters[chapter - 1].startVerse);

    handleCloseChapterModal();
    handleShowVerseModal();
  }
  const handleShowChapterModal = () => {
    setShowChapterModal(true);
  }
  const handleCloseChapterModal = () => {
    setShowChapterModal(false);
  }

  const handleSelectVerse = (verse) => {
    setVerse(verse);
    handleCloseVerseModal();
  }
  const handleShowVerseModal = () => {
    setShowVerseModal(true);
  }
  const handleCloseVerseModal = () => {
    setShowVerseModal(false);
  }

  const handleOpenVerse = () => {
    const bookIdString = bookId.toString().padStart(2, '0');
    const chapterString = chapter.toString().padStart(3, '0');
    const verseString = verse.toString().padStart(3, '0');
    window.open("https://www.jw.org/finder?srcid=jwlshare&wtlocale=J&prefer=lang&bible=" + bookIdString + chapterString + verseString + "&pub=nwtsty", '_blank');
  }

  const handleChangeText = (event) => {
    setText(event.target.value);
  }

  const handleCreateScriptureToMemorize = async () => {
    const createTime = new Date(Date.now()).toISOString();
    const data = {
      auth: auth,
      createTime: createTime,
      bookId: bookId,
      chapter: chapter,
      verse: verse,
      text: text
    };
    console.log(data);

    try {
      await fetch(getServerUrl() + "ScripturesToMemorize/create", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      setText("");

      getScripturesToMemorize();
    }
    catch (e) {
      window.alert("失敗しました。");
      console.error(e);
    }
  }

  const handleShowScriptureToMemorizeModal = () => {
    setShowScriptureToMemorizeModal(true);
  }
  const handleCloseScriptureToMemorizeModal = () => {
    setShowScriptureToMemorizeModal(false);
  }
  const handleClickScriptureToMemorize = (scriptureToMemorize) => {
    setSelectedScriptureToMemorize(scriptureToMemorize);
    handleShowScriptureToMemorizeModal();
  }
  const handleClickDelete = async () => {
    handleCloseScriptureToMemorizeModal();

    const data = {
      auth: auth,
      createTime: selectedScriptureToMemorize.createTime
    };
    console.log(data);

    try {
      await fetch(getServerUrl() + "ScripturesToMemorize/delete", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      window.alert("削除しました。");

      getScripturesToMemorize();
    }
    catch (e) {
      window.alert("失敗しました。");
      console.error(e);
    }
  }

  const handleClickFirstPage = () => {
    setPage(0);
  }

  const handleClickPrevPage = () => {
    const newPage = page > 0 ? page - 1 : 0;
    setPage(newPage);
  }

  const handleClickNextPage = () => {
    const newPage = page < pageNumber ? page + 1 : pageNumber;
    setPage(newPage);
  }

  const handleClickLastPage = () => {
    setPage(pageNumber);
  }

  return (
    <>
      <BookModal
        showModal={showBookModal}
        books={books}
        sections={sections}
        handleCloseModal={handleCloseBookModal}
        handleSelect={handleSelectBook}
      />

      <ChapterModal
        showModal={showChapterModal}
        chapterNumber={chapterNumber}
        handleCloseModal={handleCloseChapterModal}
        handleSelect={handleSelectChapter}
        bookName={bookName}
      />

      <VerseModal
        showModal={showVerseModal}
        startVerse={startVerse}
        endVerse={endVerse}
        handleCloseModal={handleCloseVerseModal}
        handleSelect={handleSelectVerse}
        bookName={bookName}
        chapter={chapter}
      />

      <ScriptureToMemorizeModal
        showModal={showScriptureToMemorizeModal}
        handleCloseModal={handleCloseScriptureToMemorizeModal}
        books={books}
        scriptureToMemorize={selectedScriptureToMemorize}
        handleClickDelete={handleClickDelete}
      />

      <Header />

      <Container className="mt-3 mb-3">
        <Row className="mb-3">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>「覚える聖句」を登録する</Accordion.Header>
              <Accordion.Body>

                <Stack direction="horizontal" className="mb-3">
                  <div>
                    <Button variant="outline-info" className="gap-book-chapter fs-4" onClick={handleShowBookModal}>{bookName}</Button>
                  </div>
                  <div>
                    <Stack direction="horizontal">
                      <Button variant="outline-info" className="gap-book-chapter fs-4" onClick={handleShowChapterModal}>{chapter}</Button>
                    </Stack>
                  </div>
                  <div>
                    :
                  </div>
                  <div>
                    <Stack direction="horizontal">
                      <Button variant="outline-info" className="gap-book-chapter fs-4" onClick={handleShowVerseModal}>{verse}</Button>
                    </Stack>
                  </div>
                  <div>
                    <Button variant="light" className="gap-left" onClick={handleOpenVerse}>開く</Button>
                  </div>
                </Stack>

                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Control as="textarea" aria-describedby="description" placeholder="聖句の本文を入力..." rows={3} value={text} onChange={(e) => handleChangeText(e)} />
                  <Form.Text id="description" muted>
                    指定した聖句の本文をご自身で入力してください。参照用の記号や改行は自動で削除されます。
                  </Form.Text>
                </Form.Group>

                <Stack direction="horizontal" className="justify-content-end">
                  <Button variant="success" type="button" onClick={handleCreateScriptureToMemorize}>
                    登録
                  </Button>
                </Stack>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>

        <Row className="mb-3">
          <Stack direction="horizontal" className="justify-content-left">
            <ButtonGroup>
              <ToggleButton
                id="radio-Recent"
                type="radio"
                variant="outline-success"
                name="Recent"
                value="Recent"
                checked={sort === "Recent"}
                onChange={(e) => setSort(e.currentTarget.value)}
              >
                登録順
              </ToggleButton>
              <ToggleButton
                id="radio-InOrder"
                type="radio"
                variant="outline-success"
                name="InOrder"
                value="InOrder"
                checked={sort === "InOrder"}
                onChange={(e) => setSort(e.currentTarget.value)}
              >
                書籍順
              </ToggleButton>
            </ButtonGroup>
          </Stack>
        </Row>

        <Row className="justify-content-center">
          <Col xs="auto">
            {startNumber} - {endNumber} 件 （全 {count} 件）
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <Pagination>
              <Pagination.First onClick={handleClickFirstPage} />
              <Pagination.Prev onClick={handleClickPrevPage} />
              <Pagination.Next onClick={handleClickNextPage} />
              <Pagination.Last onClick={handleClickLastPage} />
            </Pagination>
          </Col>
        </Row>
        <Row>
          <Col>
            <ListGroup>
              {scripturesToMemorize.map((scriptureToMemorize, index) => {
                const scripture = scriptureToMemorize.scripture;
                const script = scripture.script;
                const scriptString = getScriptString(script, books);
                return (
                  <ListGroup.Item key={index}>
                    <Stack direction="horizontal">
                      <div>
                        {scriptString}
                        &nbsp; &nbsp;
                        {scripture.text}
                      </div>
                      <div className="ms-auto">
                        <Button onClick={() => handleClickScriptureToMemorize(scriptureToMemorize)} variant="outline-secondary" size="sm"><PencilSquare></PencilSquare></Button>
                      </div>
                    </Stack>
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </>
  )
}
