import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, NavDropdown, Toast, ToastContainer } from 'react-bootstrap';
import getServerUrl from '../functions/getServerUrl';
import getDateString from '../functions/getDateString';

export default function Header() {
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [guest, setGuest] = useState(null);
  const [showCountToast, setShowCountToast] = useState(false);
  const [dateLogsCount, setDateLogsCount] = useState(0);

  const checkAccount = async () => {
    const registerAsGuestUser = async () => {
      try {
        const res = await fetch(getServerUrl() + "Users/register_as_guest", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const data = await res.json();
        console.log(data);

        return data;
      }
      catch (e) {
        console.error(e);
      }
    }

    const _userId = localStorage.getItem("userId");
    const _sessionId = localStorage.getItem("sessionId");
    const _guest = localStorage.getItem("guest")

    // ローカルストレージにユーザ情報がなければゲストユーザーとして登録する
    if (_userId === null || _sessionId === null) {
      const data = await registerAsGuestUser();

      localStorage.setItem("userId", data.userId);
      localStorage.setItem("sessionId", data.sessionId);
      localStorage.setItem("guest", true);

      window.location.reload();
    }
    else {
      setUserId(_userId);
      setSessionId(_sessionId);
      setGuest(_guest);
    }
  }

  const getUserInfo = async () => {
    const res = await fetch(getServerUrl() + "Users/me?userId=" + userId + "&sessionId=" + sessionId);

    if (res.status === 401) {
      // 認証が失敗したらユーザーデータは削除する
      localStorage.removeItem("userId");
      localStorage.removeItem("sessionId");

      window.location.href = "/login";
    }

    const data = await res.json();
    const user = data.user;

    setUserName(user.name);
  }

  const getLogsCount = async () => {
    const date = new Date();
    const dateString = getDateString(date);
    const res = await fetch(getServerUrl() + "Users/date_logs_count?userId=" + userId + "&sessionId=" + sessionId + "&date=" + dateString);
    const data = await res.json();
    const count = data.count;
    setDateLogsCount(count);

    const obtainedDate = localStorage.getItem("obtainedDate");
    if (count > 0 && obtainedDate !== dateString) {
      setShowCountToast(true);
    }
    localStorage.setItem("obtainedDate", dateString);
  }
  useEffect(() => {
    checkAccount();
  }, []);

  useEffect(() => {
    if (userId !== null) {
      getUserInfo();
      getLogsCount();
    }
  }, [userId]);

  return (
    <>
      <ToastContainer
        className="p-3"
        position="top-end"
        style={{ zIndex: 1 }}>
        <Toast onClose={() => setShowCountToast(false)} show={showCountToast} delay={3000} autohide>
          <Toast.Header closeButton={false}>
            <strong className="me-auto">今日の記録</strong>
          </Toast.Header>
          <Toast.Body>今日は「通読くん」ユーザーの通読が {dateLogsCount} 件記録されました。</Toast.Body>
        </Toast>
      </ToastContainer>

      <Navbar bg="success" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="/">通読くん</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">ホーム</Nav.Link>
              <Nav.Link href="/record">通読記録</Nav.Link>
              <NavDropdown title="グラフ" id="basic-nav-dropdown-graph">
                <NavDropdown.Item href="/graph/calendar">カレンダー</NavDropdown.Item>
                <NavDropdown.Item href="/graph/chapter">章</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/memory">覚える聖句</Nav.Link>
              <Nav.Link href="https://tsudokukun.jp/webapp" target="_blank" rel="noopener noreferrer">このサイトについて</Nav.Link>
              <NavDropdown title="今日の記録">
                <NavDropdown.Item>
                  今日は「通読くん」ユーザーの通読が {dateLogsCount} 件記録されました。
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Nav.Link href="/setting">設定</Nav.Link>
              {userId !== null &&
                <>
                  {guest !== null ?
                    <Navbar.Text>
                      {userName}
                    </Navbar.Text>
                    :
                    <NavDropdown title={userName}>
                      <NavDropdown.Item href="/logout">ログアウト</NavDropdown.Item>
                    </NavDropdown>
                  }
                </>
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}
