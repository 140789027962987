import React from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';

export default function ChapterModal({
  showModal,
  bookName,
  chapterNumber,
  handleCloseModal = f => f,
  handleSelect = f => f
}) {
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header>
        <Modal.Title>{bookName}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <Row xs={6}>
            {Array.from(Array(chapterNumber).keys()).map((chapter) => (
              <Col key={chapter + 1} className="mb-3 d-grid">
                <Button variant="outline-info" size="sm" onClick={() => handleSelect(chapter + 1)}>{chapter + 1}</Button>
              </Col>
            ))}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  )
}