export default function getWeekHeatmaps(dayHeatmaps) {
  let index = 0;
  let weekHeatmaps = [];

  while (index < dayHeatmaps.length) {
    let dayOfWeekHeatmaps = [];
    for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
      if (index >= dayHeatmaps.length) {
        // 最後の空埋め
        dayOfWeekHeatmaps.push(null);
        continue;
      }
      // indexの曜日がdayOfWeekと一致していなければnull
      let nowHeatmap = dayHeatmaps[index];
      let nowDate = new Date(nowHeatmap.date);
      if (nowDate.getDay() !== dayOfWeek) {
        // 最初の空埋め
        dayOfWeekHeatmaps.push(null);
        continue;
      }

      // indexのデータを描画用変数に格納
      dayOfWeekHeatmaps.push(dayHeatmaps[index]);

      index += 1;
    }

    weekHeatmaps.push(dayOfWeekHeatmaps);
  }

  return weekHeatmaps;
}
