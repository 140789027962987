export default function isReadingRangeWholeChapter(readingRange) {
    const book = readingRange.book;
    const chapter = readingRange.chapter;
    const startVerse = readingRange.startVerse;
    const endVerse = readingRange.endVerse;

    if (book.chapters[chapter - 1].startVerse === startVerse &&
        book.chapters[chapter - 1].endVerse === endVerse) {
        return true;
    }
    return false;
}