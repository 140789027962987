export default function getCommentString(comment) {
  let commentString = "";
  if (comment.aboutJehovah !== "") {
    commentString += "1. " + comment.aboutJehovah;
  }
  if (comment.aboutContribute !== "") {
    if (commentString.length !== 0) commentString += " ; ";
    commentString += "2. " + comment.aboutContribute;
  }
  if (comment.aboutApply !== "") {
    if (commentString.length !== 0) commentString += " ; ";
    commentString += "3. " + comment.aboutApply;
  }
  if (comment.aboutHelp !== "") {
    if (commentString.length !== 0) commentString += " : ";
    commentString += "4. " + comment.aboutHelp;
  }
  return commentString;
}
