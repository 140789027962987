import React, { useEffect } from 'react';
import getServerUrl from '../functions/getServerUrl';
import getLINERedirectUri from '../functions/getLINERedirectUri';

export default function LINERedirect() {
  function getParam(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  const login = async (state, code, redirectUri) => {
    try {
      const body = {
        state: state,
        code: code,
        redirectUri: redirectUri
      };
      const res = await fetch(getServerUrl() + "Auth/line/login", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      const data = await res.json();
      console.log(data);
      const success = data.success;
      const userId = data.userId;
      const sessionId = data.sessionId;

      if (success) {
        localStorage.setItem("userId", userId);
        localStorage.setItem("sessionId", sessionId);
        localStorage.removeItem("authType");
        localStorage.removeItem("guest");

        window.location.href = "/";
      }
      else {
        throw new Error();
      }
    }
    catch (e) {
      window.alert("認証が失敗しました。タイムアウトが発生したか、このアカウントが存在しません。");
      window.location.href = "/login";
    }
  }

  const logon = async (state, code, redirectUri) => {
    try {
      const body = {
        state: state,
        code: code,
        redirectUri: redirectUri
      };
      const res = await fetch(getServerUrl() + "Auth/line/logon", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      const data = await res.json();
      console.log(data);
      const success = data.success;
      const userId = data.userId;
      const sessionId = data.sessionId;

      if (success) {
        localStorage.setItem("userId", userId);
        localStorage.setItem("sessionId", sessionId);
        localStorage.removeItem("authType");
        localStorage.removeItem("guest");

        window.location.href = "/";
      }
      else {
        throw new Error();
      }
    }
    catch (e) {
      window.alert("認証が失敗しました。タイムアウトが発生したか、このLINEアカウントは既に登録されています。");
      window.location.href = "/logon";
    }
  }

  const logonFromGuest = async (state, code, redirectUri) => {
    try {
      const userId = localStorage.getItem("userId");
      const sessionId = localStorage.getItem("sessionId");
      const body = {
        auth: {
          userId: userId,
          sessionId: sessionId
        },
        state: state,
        code: code,
        redirectUri: redirectUri
      };
      const res = await fetch(getServerUrl() + "Auth/line/logon_from_guest", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      const data = await res.json();
      console.log(data);
      const success = data.success;

      if (success) {
        localStorage.removeItem("authType");
        localStorage.removeItem("guest");

        window.location.href = "/";
      }
      else {
        throw new Error();
      }
    }
    catch (e) {
      window.alert("認証が失敗しました。タイムアウトが発生したか、このLINEアカウントは既に登録されています。");
      window.location.href = "/logon_from_guest";
    }
  }

  const add = async (state, code, redirectUri) => {
    try {
      const userId = localStorage.getItem("userId");
      const sessionId = localStorage.getItem("sessionId");
      const body = {
        auth: {
          userId: userId,
          sessionId: sessionId
        },
        state: state,
        code: code,
        redirectUri: redirectUri
      };
      const res = await fetch(getServerUrl() + "Auth/line/add", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      const data = await res.json();
      console.log(data);
      const success = data.success;

      if (success) {
        localStorage.removeItem("authType");
        localStorage.removeItem("guest");

        window.location.href = "/setting";
      }
      else {
        throw new Error();
      }
    }
    catch (e) {
      window.alert("認証が失敗しました。タイムアウトが発生したか、このLINEアカウントは既に登録されています。");
      window.location.href = "/setting";
    }
  }

  useEffect(() => {
    const authType = localStorage.getItem("authType");
    const state = getParam("state");
    const code = getParam("code");
    const redirectUri = getLINERedirectUri();

    if (authType === "login") {
      login(state, code, redirectUri);
    }
    else if (authType === "logon") {
      logon(state, code, redirectUri);
    }
    else if (authType === "logonFromGuest") {
      logonFromGuest(state, code, redirectUri);
    }
    else if (authType === "add") {
      add(state, code, redirectUri);
    }
  }, []);

  return (
    <p>確認中です。少々お待ちください。</p>
  )
}
