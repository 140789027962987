export default function getReadingRangeString(readingRange) {
  if (readingRange.book === null) return "";

  const book = readingRange.book;
  const chapter = readingRange.chapter;
  const startVerse = readingRange.startVerse;
  const endVerse = readingRange.endVerse;

  let readingRangeString = "";
  if (startVerse === book.chapters[chapter - 1].startVerse && endVerse === book.chapters[chapter - 1].endVerse) {
    // 章全体
    readingRangeString = book.name + chapter.toString();
    if (book.id === 19) {
      readingRangeString += "編";
    }
    else {
      readingRangeString += "章";
    }
  }
  else if (startVerse === endVerse) {
    readingRangeString = book.name + chapter.toString() + ":" + startVerse.toString();
  }
  else if (startVerse + 1 === endVerse) {
    readingRangeString = book.name + chapter.toString() + ":" + startVerse.toString() + "," + endVerse.toString();
  }
  else {
    readingRangeString = book.name + chapter.toString() + ":" + startVerse.toString() + "-" + endVerse.toString();
  }

  return readingRangeString;
}
