import React, { useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import getServerUrl from '../functions/getServerUrl';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function EmailCheck() {
  const [processing, setProcessing] = useState(false);
  const [passcode, setPasscode] = useState("");

  const userId = localStorage.getItem("userId");
  const sessionId = localStorage.getItem("sessionId");
  const authType = localStorage.getItem("authType");
  const email = sessionStorage.getItem("email");

  const handleChangePasscode = (event) => {
    setPasscode(event.target.value);
  }

  const handleLogin = () => {
    login();
  }

  const handleLogon = () => {
    logon();
  }

  const handleLogonFromGuest = () => {
    logonFromGuest();
  }

  const handleAdd = () => {
    add();
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (authType === "login") {
        login();
      }
      else if (authType === "logon") {
        logon();
      }
      else if (authType === "logonFromGuest") {
        logonFromGuest();
      }
      else if (authType === "add") {
        add();
      }
    }
  }

  const login = () => {
    setProcessing(true);

    const data = {
      address: email,
      passcode: passcode
    };
    fetch(getServerUrl() + "Auth/email/login", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => {
        console.log(res);
        if (res.status === 200) {
          return res.json();
        }
      })
      .then(json => {
        if (json === undefined) {
          return;
        }

        console.log(json);
        const success = json.success;
        const userId = json.userId;
        const sessionId = json.sessionId;
        if (success) {
          localStorage.setItem("userId", userId);
          localStorage.setItem("sessionId", sessionId);
          localStorage.removeItem("authType");
          localStorage.removeItem("guest");

          window.location.href = "/";
        }
        else {
          window.alert("パスコードが間違っているか有効期限が過ぎています。もしくはこのメールアドレスのアカウントが存在しません。");
          window.location.reload();
        }
      });
  }

  const logon = () => {
    setProcessing(true);

    const data = {
      address: email,
      passcode: passcode
    };
    fetch(getServerUrl() + "Auth/email/logon", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => {
        console.log(res);
        if (res.status === 200) {
          return res.json();
        }
      })
      .then(json => {
        if (json === undefined) {
          return;
        }

        console.log(json);
        const success = json.success;
        const userId = json.userId;
        const sessionId = json.sessionId;
        if (success) {
          localStorage.setItem("userId", userId);
          localStorage.setItem("sessionId", sessionId);
          localStorage.removeItem("authType");
          localStorage.removeItem("guest");

          window.location.href = "/";
        }
        else {
          window.alert("パスコードが間違っているか有効期限が過ぎています。もしくはこのメールアドレスは既に使われています。");
          window.location.reload();
        }
      });
  }

  const logonFromGuest = () => {
    setProcessing(true);

    const data = {
      auth: {
        userId: userId,
        sessionId: sessionId
      },
      address: email,
      passcode: passcode
    };
    fetch(getServerUrl() + "Auth/email/logon_from_guest", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => {
        console.log(res);
        if (res.status === 200) {
          return res.json();
        }
      })
      .then(json => {
        if (json === undefined) {
          return;
        }

        console.log(json);
        const success = json.success;
        if (success) {
          localStorage.removeItem("authType");
          localStorage.removeItem("guest");

          window.location.href = "/";
        }
        else {
          window.alert("パスコードが間違っているか有効期限が過ぎています。もしくはこのメールアドレスは既に使われています。");
          window.location.reload();
        }
      });
  }

  const add = () => {
    setProcessing(true);

    const data = {
      auth: {
        userId: userId,
        sessionId: sessionId
      },
      address: email,
      passcode: passcode
    };
    fetch(getServerUrl() + "Auth/email/add", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => {
        console.log(res);
        if (res.status === 200) {
          return res.json();
        }
      })
      .then(json => {
        if (json === undefined) {
          return;
        }

        console.log(json);
        const success = json.success;
        if (success) {
          localStorage.removeItem("authType");
          localStorage.removeItem("guest");

          window.location.href = "/setting";
        }
        else {
          window.alert("パスコードが間違っているか有効期限が過ぎています。もしくはこのメールアドレスは既に使われています。");
          window.location.reload();
        }
      });
  }

  return (
    <>
      <div className="color-overlay d-flex justify-content-center align-items-center">
        <Form className="form rounded p-4 p-sm-3">

          {processing === true ?
            <div className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
            :
            <>
              <h2 className="mb-3">パスコード確認</h2>
              <Form.Group className="mb-2">
                <Form.Label>パスコード</Form.Label>
                <Form.Control type="text" value={passcode} onChange={(e) => handleChangePasscode(e)} onKeyDown={(e) => handleKeyDown(e)} />
                <Form.Text className="text-muted">
                  {email} にメールを送信しました。パスコードを確認して入力してください。
                </Form.Text>
              </Form.Group>

              {authType === "login" &&
                <Button className="mb-3" variant="success" type="button" onClick={handleLogin} >
                  ログイン
                </Button>
              }
              {authType === "logon" &&
                <Button className="mb-3" variant="success" type="button" onClick={handleLogon} >
                  アカウント登録
                </Button>
              }
              {authType === "logonFromGuest" &&
                <Button className="mb-3" variant="success" type="button" onClick={handleLogonFromGuest} >
                  ゲストユーザーから正規ユーザーとしてアカウント登録
                </Button>
              }
              {authType === "add" &&
                <Button className="mb-3" variant="success" type="button" onClick={handleAdd} >
                  追加
                </Button>
              }

              <div>
                <a href="javascript:history.back()">戻る</a>
              </div>
            </>
          }

        </Form>
      </div>
    </>
  );
}
